/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useMemo } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { GlobalState, TicketTypeModel } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  selectContent,
  selectCurrencyConfig,
  selectDisplayOriginalPrice,
  selectOriginalPriceToDisplayInTicketsStep,
  selectTicketBookingFeeInTicketsStep,
  selectUseBookingFeeStrategyInTickets,
} from '../../../store/Selectors';

interface Props {
  ticket: TicketTypeModel;
}

const TicketPriceWithBookingFeeTax: React.FC<Props> = ({ ticket }) => {
  const content = useSelector(selectContent);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const { useBookingFeeStrategy, perItemFee, perItemTax } = useSelector(
    selectUseBookingFeeStrategyInTickets
  );
  const bookingFee = useSelector((state: GlobalState) =>
    selectTicketBookingFeeInTicketsStep(state, ticket)
  );
  const displayOriginalPrice = useSelector((state: GlobalState) =>
    selectDisplayOriginalPrice(state, ticket)
  );
  const originalPriceToDisplayInTicketsStep = useSelector(
    (state: GlobalState) =>
      selectOriginalPriceToDisplayInTicketsStep(state, ticket, bookingFee)
  );
  const { bookingFeeExclTax, bookingFeeTax } = useMemo(() => {
    let fee = 0;
    let tax = 0;
    if (useBookingFeeStrategy) {
      fee = perItemFee;
      tax = perItemTax;
    } else if (ticket.bookingFee) {
      fee = ticket.bookingFee - ticket.bookingFeeTax;
      tax = ticket.bookingFeeTax;
    }
    return { bookingFeeExclTax: fee, bookingFeeTax: tax };
  }, [perItemFee, perItemTax, ticket, useBookingFeeStrategy]);

  const ticketPriceMinusTax = ticket.price - ticket.tax;
  const totalTax = bookingFeeTax + ticket.tax;
  const ticketPriceIncludingFeeAndTaxes =
    ticketPriceMinusTax + bookingFeeExclTax + totalTax;

  const displayBookingFee = displayPrice(bookingFeeExclTax, currencyConfig);
  const displayTax = displayPrice(totalTax, currencyConfig);
  const displayPriceMinusTax = displayPrice(
    ticketPriceMinusTax,
    currencyConfig
  );
  const additionalPricingText = ` (${displayPriceMinusTax} + ${displayBookingFee} ${content.cartSummary.serviceChargeLabel} + ${displayTax} ${content.cartSummary.taxLabel})`;

  return (
    <>
      <p>
        {displayOriginalPrice && (
          <span className='original-price'>
            {displayPrice(originalPriceToDisplayInTicketsStep, currencyConfig)}
          </span>
        )}
        <b
          className={classnames(displayOriginalPrice && 'ticket-promo-price')}
          sx={{
            '&.ticket-promo-price': {
              color: 'primary',
            },
          }}
        >
          {displayPrice(ticketPriceIncludingFeeAndTaxes, currencyConfig)}
        </b>
        {ticket.quantity > 0 && (
          <>
            <span> x </span>
            <span className='quantity'>{ticket.quantity}</span>
          </>
        )}
      </p>
      <p className='tiny'>{additionalPricingText}</p>
    </>
  );
};

export default TicketPriceWithBookingFeeTax;
