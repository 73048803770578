export enum TicketGroupsOrder {
  MEMBER_PACKAGE_STANDARD = 0,
  MEMBER_STANDARD_PACKAGE = 1,
  PACKAGE_MEMBER_STANDARD = 2,
  PACKAGE_STANDARD_MEMBER = 3,
  STANDARD_MEMBER_PACKAGE = 4,
  STANDARD_PACKAGE_MEMBER = 5,
}

export enum PaymentProvidersEnum {
  STRIPEPOPUP = 0,
  RTSVANTIV = 1,
  RTSCARDPAYMENT = 2,
  INTEGRATEDPAYMENT = 3,
  PAYBOX = 4,
  VANTIVHP = 5,
  PEACH = 6,
  STRIPEV2 = 7,
  VANTIVHPIFRAME = 9,
  SPREEDLY = 10,
  ADYEN = 11,
  RTSHEARTLAND = 13,
  PAYRIX = 14,
  WORLDPAYAPPLEPAY = 15,
  AMAZONPAYALEXABOOST = 98,
  AMAZONPAYALEXA = 99,
  AMAZONPAY = 100,
}

export enum RefundServiceCharge {
  NO = 0,
  YES = 1,
  LOYALTY_ONLY = 2,
}

export enum BookingFeeType {
  NONE = 0,
  PERITEM = 1,
  PERSALE = 2,
  PCTPERITEM = 3,
  PCTPERSALE = 4,
}

export enum DisplayBookingFee {
  DISABLED = 0,
  INFOBOX = 1,
  TICKETS = 2,
  INFOBOXANDTICKETS = 3,
  TICKETSWITHTAX = 4,
}

export enum DeliveryPreference {
  ORDER_TO_SEAT = 'order-to-seat',
  COLLECT_FROM_KIOSK = 'collect-from-kiosk',
}

export enum TicketExclusionStrategyType {
  NONE = 0,
  CEA = 1,
}

export enum ButtonStyles {
  SOLID = 0,
  BORDER = 1,
}

export enum FormInputStyles {
  WHITE_BACKGROUND = 0,
  THEME_BACKGROUND = 1,
}

export enum TotalBarColors {
  PRIMARY = 0,
  ACCENT = 1,
  HIGHLIGHT = 2,
  MUTED = 3,
}

export enum SidebarColors {
  WEBSITE_BACKGROUND = 0,
  ACCENT = 1,
  HIGHLIGHT = 2,
  MUTED = 3,
}

export enum FoodAndBeverageJourneyDeliveryType {
  Disabled = 0,
  CollectFromKioskOnly = 1,
  CollectFromKioskAndOrderToSeat = 2,
  OrderToSeatOnly = 3,
}

export enum FoodAndBeverageDeliveryWindowsDisplayOptionEnum {
  DoNotDisplayDeliveryWindows = 0,
  DisplayDeliveryWindowsOnDeliverToMySeat = 1,
  DisplayDeliveryWindowsOnPickupFromKiosk = 2,
}

export enum OrderType {
  Unknown = 0,
  Ticketing = 1,
  Concessions = 2,
  Giftcards = 3,
  Memberships = 4,
  PhysicalGiftCards = 5,
  EVoucher = 6,
  LoyaltyUpgrade = 7,
  TicketUpgrade = 8,
  SeasonPass = 9,
}

export enum GiftCardDeliveryStateTypeEnum {
  Disabled = 0,
  Enabled = 1,
  EnabledWithUsLookup = 2,
}

export enum TicketLongDescriptionSetting {
  NoDescription = 0,
  PackageTickets = 1,
  AllTickets = 2,
}
