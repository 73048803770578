import { defineMessages } from 'react-intl';

export default defineMessages({
  atLabel: {
    id: 'global.at-label',
    defaultMessage: 'At',
  },
  onLabel: {
    id: 'global.on-label',
    defaultMessage: 'On',
  },
});
