/** @jsxImportSource theme-ui */
import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import {
  selectContent,
  selectSelectedSeasonPassItem,
} from '../../../store/Selectors';
import SeasonPassSeatsMoviesContainer from '../../common/seasonpass/SeasonPassSeatsMoviesContainer';
import SelectedSeasonPassStepContainer from '../../common/seasonpass/SelectedSeasonPassStepContainer';

const SeasonPassSeats: React.FC = () => {
  const content = useSelector(selectContent);
  const boostNavigate = useBoostNavigate();
  const selectedSeasonPassItem = useSelector(selectSelectedSeasonPassItem);

  const stepRequiredDataAreValid =
    content?.seasonPass && selectedSeasonPassItem;

  useEffect(() => {
    if (!stepRequiredDataAreValid) {
      boostNavigate.navigateToStartJourney();
    }
  }, [boostNavigate, stepRequiredDataAreValid]);

  if (!stepRequiredDataAreValid) {
    return null;
  }

  const handleContinueClick = () => {
    boostNavigate.navigateToNextStep();
  };

  return (
    <SelectedSeasonPassStepContainer
      pageTitle={content.seasonPass.confirmSeatsTitle}
      pageText={content.seasonPass.confirmSeatsRichText}
      continueButtonText={content.continueButtonText}
      onContinueClicked={() => handleContinueClick()}
      disableContinueButton={false}
    >
      <SeasonPassSeatsMoviesContainer />
    </SelectedSeasonPassStepContainer>
  );
};

export default SeasonPassSeats;
