import React, { FC, memo } from 'react';

import { WidgetData } from '../types';
type Props = {
  widget: WidgetData<'TicketingCMSJourneyDividerWidget'>;
};

export const Divider: FC<Props> = () => {
  return (
    <div className='row widget-row'>
      <div className='contained col-12'>
        <div className='separator'></div>
      </div>
    </div>
  );
};

export default memo(Divider);
