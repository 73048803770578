import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { cinemaHomeUrlKey } from '../../../constants';
import { getContentForError } from '../../../services/PeachErrorResolver';
import { selectContent } from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import Heading from '../../common/heading/Heading';
import RichText from '../../common/richtext/RichText';

const ErrorPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  const content = useSelector(selectContent);

  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const errorCode = parseInt(searchParams.get('code') ?? '', 10);

    const message =
      content && !isNaN(errorCode)
        ? getContentForError(errorCode, content)
        : '';

    setErrorMessage(message);
  }, [content, searchParams]);

  const title = content?.error?.title ?? 'Ooops, there was a problem.';

  const cinemaHomeUrl = window.sessionStorage.getItem(cinemaHomeUrlKey);

  const handleGoToCinemaWebsiteClick = () => {
    if (cinemaHomeUrl) window.location.replace(cinemaHomeUrl);
  };

  return (
    <div className='error' data-testid='error'>
      <Row>
        <Col xs={12} className='contained'>
          <Heading size={2}>{title}</Heading>
          {!!content && <RichText text={errorMessage} />}
          {!!cinemaHomeUrl && (
            <ActionButton
              onClick={handleGoToCinemaWebsiteClick}
              showIcon
              contained
              mx=''
              variant='primary'
            >
              Go to Cinema website
            </ActionButton>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ErrorPage;
