import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useBoostNavigate } from './useBoostNavigate';

import { JOURNEY_TYPES } from '../constants';
import {
  selectConfig,
  selectBookingData,
  selectToken,
  selectJourneyTypeConfig,
} from '../store/Selectors';

export const useValidateJourneyShowtime = () => {
  const boostNavigate = useBoostNavigate();
  const { externalCinemaId, externalSessionId } = useParams();

  const config = useSelector(selectConfig);
  const bookingData = useSelector(selectBookingData);
  const token = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);

  const isValidRouteData =
    !!config &&
    !!token &&
    (!externalSessionId ||
      externalSessionId === bookingData.externalSessionId) &&
    (!externalCinemaId || externalCinemaId === bookingData.externalCinemaId);

  useEffect(() => {
    if (
      isValidRouteData ||
      journeyTypeConfig.isConcessionsOnlyJourney ||
      journeyTypeConfig.type === JOURNEY_TYPES.SEASON_PASS
    )
      return;

    boostNavigate.navigateToStartJourney();
  }, [
    boostNavigate,
    isValidRouteData,
    journeyTypeConfig.isConcessionsOnlyJourney,
    journeyTypeConfig.type,
  ]);
};
