import React, { FC, memo } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { selectDazzlerLocale } from '../../../store/Selectors';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyHeadingWidget'>;
};

export const Heading: FC<Props> = ({ widget }) => {
  const selectedLocale = useSelector(selectDazzlerLocale);
  const localizedContent =
    widget.shape?.content.find((content) => content.locale === selectedLocale)
      ?.value ?? '';
  const returnHeading = () => {
    switch (widget.shape?.level) {
      case 1:
        return <h1>{localizedContent}</h1>;
      case 2:
        return <h2>{localizedContent}</h2>;
      case 3:
        return <h3>{localizedContent}</h3>;
      default:
        return <h1>{localizedContent}</h1>;
    }
  };

  const textAlign = {
    LEFT: 'left',
    CENTER: 'center',
  };

  const selectedAlign = widget.shape?.textAlign ?? 'LEFT';

  return (
    <div className='row widget-row'>
      <div
        className={classnames(
          `contained col-12 text-${textAlign[selectedAlign]}`
        )}
      >
        {returnHeading()}
      </div>
    </div>
  );
};

export default memo(Heading);
