import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { selectContent, selectSeasonPasses } from '../../../store/Selectors';
import Heading from '../../common/heading/Heading';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';
import SeasonPassItem from '../../common/seasonpass/SeasonPassItem';

const SelectSeasonPass: React.FC = () => {
  const boostNavigate = useBoostNavigate();

  const seasonPasses = useSelector(selectSeasonPasses);
  const content = useSelector(selectContent);

  const stepRequiredDataAreValid = content?.seasonPass;

  useEffect(() => {
    if (!stepRequiredDataAreValid) {
      boostNavigate.navigateToStartJourney();
    }
  }, [boostNavigate, stepRequiredDataAreValid]);

  if (!stepRequiredDataAreValid) {
    return null;
  }

  return (
    <div className='season-pass-select'>
      <div>
        <ContainedRow classNameCol='text-center'>
          <Heading className='mb-2' size={1}>
            {content.seasonPass.selectSeasonPassTitle}
          </Heading>
        </ContainedRow>
        <RichText text={content.seasonPass.selectSeasonPassRichText} />
      </div>
      {seasonPasses?.map((sP) => (
        <SeasonPassItem key={sP.id} seasonPass={sP} />
      ))}
    </div>
  );
};

export default SelectSeasonPass;
