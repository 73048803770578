import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, Outlet } from 'react-router-dom';
import { Container } from 'theme-ui';

import CartSummaryOffcanvas from './CartSummaryOffcanvas';
import ErrorModal from './ErrorModal';
import LayoutHelmet from './LayoutHelmet';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { useForceRestartJourney } from '../../../hooks/layout/useForceRestartJourney';
import { useRemoveNoScroll } from '../../../hooks/layout/useRemoveNoScroll';
import { useStepUpdater } from '../../../hooks/layout/useStepUpdater';
import { useUnloadListeners } from '../../../hooks/layout/useUnloadListeners';
import setContentSecurityPolicy from '../../../scripts/setContentSecurityPolicy';
import { shouldHideCartSummary } from '../../../services/Helpers';
import {
  selectContentSecurityPolicyMetaTagContent,
  selectEnableContentSecurityPolicy,
  selectJourneyTypeConfig,
  selectStep,
} from '../../../store/Selectors';
import CartSummary from '../cartsummary/CartSummary';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import TotalWrapper from '../totalwrapper/TotalWrapper';

const Layout: React.FC = () => {
  if (window.top && window.location !== window.top.location) {
    window.top.location = window.location;
  }

  const { pathname } = useLocation();
  const { isLargeScreenWidth } = useScreenWidth();

  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const step = useSelector(selectStep);
  const hideCartSummary = shouldHideCartSummary(pathname);
  const contentSecurityPolicyMetaTagContent = useSelector(
    selectContentSecurityPolicyMetaTagContent
  );
  const enableContentSecurityPolicy = useSelector(
    selectEnableContentSecurityPolicy
  );

  const helmetLoaded = setContentSecurityPolicy(
    contentSecurityPolicyMetaTagContent,
    enableContentSecurityPolicy
  );

  useForceRestartJourney();

  useUnloadListeners();

  useRemoveNoScroll();

  useStepUpdater();

  if (!helmetLoaded) return null;

  return (
    <>
      <LayoutHelmet />
      <Container
        className='layout'
        variant='layout.fluid'
        px={4}
        data-testid='layout'
      >
        <Row className='layout-top'>
          <Col lg={hideCartSummary ? 12 : 8} className='pb-3 pb-md-6 view'>
            {!hideCartSummary && !isLargeScreenWidth && <TotalWrapper />}
            <Header />
            <Outlet />
          </Col>
          {!hideCartSummary && isLargeScreenWidth && (
            <CartSummary
              hideTotal={journeyTypeConfig.isSeatsFirst && step < 2}
            />
          )}
        </Row>
        <Footer />
        <ErrorModal />
        <CartSummaryOffcanvas />
      </Container>
    </>
  );
};

export default Layout;
