import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import CartSummaryDealsApplied from './CartSummaryDealsApplied';
import CartSummaryTicketOriginalPriceDisplay from './CartSummaryTicketOriginalPriceDisplay';

import { TicketTypeModel } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  selectCurrencyConfig,
  selectTicketTypes,
} from '../../../store/Selectors';

interface Props {
  hideTax: boolean;
}

const CartSummaryTickets: React.FC<Props> = ({ hideTax }) => {
  const currencyConfig = useSelector(selectCurrencyConfig);
  const ticketTypes = useSelector(selectTicketTypes);
  const hasTickets =
    ticketTypes &&
    ticketTypes.ticketTypeModels.find((t: TicketTypeModel) => t.quantity > 0);

  if (!ticketTypes || !hasTickets) return null;

  return (
    <div className='cart-summary-row pb-0' data-testid='cs-tickets'>
      {ticketTypes.ticketTypeModels.map((t: TicketTypeModel, i: number) => {
        if (!t.quantity) return null;
        const priceToDisplay = hideTax ? t.price : t.price - t.tax;
        return (
          <Row key={`${t.id}_${i}`} className='g-0 mb-2'>
            <Col xs={9} className='text-start'>
              {t.voucherCode ? t.voucherDisplayName : t.displayName}
              <CartSummaryTicketOriginalPriceDisplay ticket={t} />
            </Col>
            <Col xs={1} className='text-center tickets-quantity'>
              {t.quantity}
            </Col>
            <Col xs={2} className='text-end'>
              {displayPrice(
                (t.quantity * (priceToDisplay * 100)) / 100,
                currencyConfig
              )}
            </Col>
          </Row>
        );
      })}
      <CartSummaryDealsApplied />
    </div>
  );
};

export default CartSummaryTickets;
