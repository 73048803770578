/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import SeasonPassImage from './SeasonPassImage';

import { SeasonPass } from '../../../@types/modelTypes';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectContent,
  selectSeasonPasses,
  selectSelectedSeasonPass,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';

interface Props {
  seasonPass: SeasonPass;
}

const SeasonPassItem: React.FC<Props> = ({ seasonPass }) => {
  const boostNavigate = useBoostNavigate();
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const seasonPasses = useSelector(selectSeasonPasses);
  const selectedSeasonPass = useSelector(selectSelectedSeasonPass);

  const isLast = seasonPasses[seasonPasses.length - 1] === seasonPass;

  const handleClick = () => {
    selectedSeasonPass.seasonPassItem = seasonPass;
    selectedSeasonPass.sessions = [];
    dispatch(actionCreators.setSelectedSeasonPass(selectedSeasonPass));

    dispatch(actionCreators.setSeasonPassMovies([]));

    boostNavigate.navigateToNextStep();
  };

  return (
    <div className='season-pass-item mt-4' id={seasonPass.id}>
      <SeasonPassImage
        imageUrl={seasonPass.imageUrl}
        title={seasonPass.title}
      />
      <div
        sx={{
          borderBottom: isLast ? 'none' : 'bodySeparator',
        }}
      >
        <h2 className='mt-1'>{seasonPass.title}</h2>
        <p className='mb-2'>{seasonPass.description}</p>
        <div className='mb-1'>
          <ActionButton
            onClick={() => handleClick()}
            showIcon
            contained
            variant='primary'
            mx='mx-3'
          >
            {content.seasonPass.selectSeasonPassButtonText}
          </ActionButton>
        </div>
      </div>
    </div>
  );
};

export default SeasonPassItem;
