import { useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { forceNewJourneyKey } from '../../constants';
import {
  selectBookingData,
  selectCartSummary,
  selectConfig,
  selectJourneyTypeConfig,
} from '../../store/Selectors';
import { useBoostNavigate } from '../useBoostNavigate';

export const useForceRestartJourney = () => {
  const { externalCinemaId, externalSessionId } = useParams();
  const { pathname } = useLocation();
  const boostNavigate = useBoostNavigate();
  const [cookies] = useCookies();

  const bookingData = useSelector(selectBookingData);
  const cartSummary = useSelector(selectCartSummary);
  const config = useSelector(selectConfig);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);

  const { isShowtimeChange } = cookies;

  const isValidRouteData =
    bookingData &&
    (!externalSessionId ||
      externalSessionId === bookingData.externalSessionId) &&
    (!externalCinemaId || externalCinemaId === bookingData.externalCinemaId);

  const isLoyaltyOnlyTicketingWithGuest =
    cartSummary?.isLoyaltyOnlyShowtime &&
    !bookingData?.isUserValidated &&
    !pathname.startsWith('/start') &&
    !pathname.startsWith('/login') &&
    !pathname.startsWith('/confirmation') &&
    !pathname.startsWith('/warning');

  const shouldRemoveNewJourneyKey =
    (isValidRouteData &&
      window.sessionStorage.getItem(forceNewJourneyKey) === '1') ||
    isLoyaltyOnlyTicketingWithGuest;

  const shouldRedirect = !isShowtimeChange && shouldRemoveNewJourneyKey;

  const isConfirmation = pathname.includes('/confirmation/');
  const isRefund = pathname.includes('/refund/');

  useEffect(() => {
    if (
      !shouldRedirect ||
      !journeyTypeConfig ||
      !config ||
      isConfirmation ||
      isRefund
    )
      return;

    boostNavigate.navigateToStartJourney();
  }, [
    boostNavigate,
    config,
    isConfirmation,
    isRefund,
    journeyTypeConfig,
    shouldRedirect,
  ]);
};
