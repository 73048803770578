export default {
  noError: 0,
  unknownError: 1,
  paymentDeclined: 2,
  paymentTakenWithError: 3,
  orderHasBeenProcessed: 4,
  noTicketReturn: 5,
  sessionExpired: 7,
  generalError: 9,
  providerNoReturnData: 10,
  invalidExternalData: 12,
  seatsUnavailable: 13,
  providerInternalError: 14,
  invalidOrder: 16,
  paymentError: 17,
  userCancelled: 18,
  securityIssue: 19,
  paymentInvalid: 21,
  concessionsCouldNotBeAdded: 22,
  sessionSoldOut: 25,
  loyaltyUserNameAlreadyExists: 34,
  hostedPaymentPaymentDeclined: 41,
  loyaltyMemberExpired: 46,
  memberDetailsIncorrect: 47,
  problemGiftCardProvider: 52,
  orderHasTimeout: 55,
  loyaltyMemberAlreadyExists: 60,
  refunded: 64,
  refundFailed: 65,
  giftCardPaymentBalanceInSufficient: 66,
  needComplementaryPayment: 69,
  splitPaymentFailed: 70,
  chooseOtherSeats: 71,
  paymentMightHaveBeenTaken: 73,
  unavailablePromotionTicket: 86,
  ceaTicketInsufficient: 87,
  seatsUnavailableRefreshLayout: 177,
  seatingCapacityExceeded: 183,
  recaptchaFailed: 184,
  turnstileFailed: 185,
  invalidMemberCard: 186,
  ok: 1000,
  posError: 1005,
  tooLateForRefund: 1008,
  orderNotFound: 1009,
  orderAlreadyRefunded: 1010,
  hostedPaymentCardTypeNotAccepted: 1011,
  gratuityNotAccepted: 1012,
  orderMaxRetriesReached: 1013,
  loyaltyMemberBlocked: 1019,
  sessionNotBookable: 1023,
  unexpectedConfigurationFound: 2000,
  tooEarlyToOrderFoodAndBeverage: 2001,
  tooLateToOrderFoodAndBeverage: 2002,
  foodAndBeverageDisabled: 2003,
  refundNotPermitted: 2005,
};
