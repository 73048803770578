import moment from 'moment';

import * as ActionTypes from './Actions';
import { initialState, noError } from './InitialGlobalState';

import { findModifiedConcessionMatch } from '../services/KioskHelpers';

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return { ...state, loading: action.loading };
    case ActionTypes.CLEAR_SESSION:
      return { ...initialState };
    case ActionTypes.INITIALIZE_SESSION:
      return { ...state, ...action.session };
    case ActionTypes.SET_REQUEST_DATA:
      return { ...state, requestData: action.requestData };
    case ActionTypes.SET_CONFIRMATION: {
      const stripUnderscoreFromId = (id) => id?.substring(id.indexOf('_') + 1);

      let deliveryItemIds = state.selectedGiftCards?.list
        ?.filter((x) => x.isDeliveryItem)
        ?.map((y) => stripUnderscoreFromId(y.id));
      let giftCardItemIds = state.selectedGiftCards?.list?.map((y) =>
        stripUnderscoreFromId(y.id)
      );
      let newGiftCards = action.confirmation.selectedConcessions?.list?.filter(
        (x) => giftCardItemIds.includes(x.id)
      );
      let newSelectedConcessions =
        action.confirmation.selectedConcessions?.list?.filter(
          (x) => !giftCardItemIds.includes(x.id)
        );
      newGiftCards.forEach((x) => {
        if (deliveryItemIds.includes(x.id)) {
          x.isDeliveryItem = true;
        }
      });
      action.confirmation.selectedConcessions.list = newSelectedConcessions;
      action.confirmation.selectedGiftCards.list = newGiftCards;
      return { ...state, ...action.confirmation };
    }
    case ActionTypes.SET_TOKEN:
      return { ...state, token: action.token };
    case ActionTypes.SET_TICKET_TYPES:
      return {
        ...state,
        ticketTypes: action.ticketTypes,
      };
    case ActionTypes.SET_HAS_THIRD_PARTY_MEMBER_TICKETS:
      return { ...state, hasThirdPartyMemberTickets: action.bool };
    case ActionTypes.SET_AVAILABLE_TICKETS:
      return {
        ...state,
        availablePosTickets: { ...action.availablePosTickets },
        ticketTypes: undefined,
      };
    case ActionTypes.ADD_VOUCHER_TICKETS:
      return { ...state, availablePosTickets: action.availablePosTickets };
    case ActionTypes.SET_SELECTED_DONATION:
      return {
        ...state,
        selectedDonation: {
          isCustomAmount: action.isCustomAmount,
          amount: action.amount,
        },
      };
    case ActionTypes.SET_DONATION:
      return {
        ...state,
        donation: action.amount,
      };
    case ActionTypes.SET_SELECTED_GRATUITY:
      return {
        ...state,
        selectedGratuity: {
          isCustomAmount: action.isCustomAmount,
          amount: action.amount,
          percentage: action.percentage,
        },
      };
    case ActionTypes.SET_LOYALTY_REDEMPTION_REWARDS:
      return { ...state, loyaltyRedemptionRewards: action.value };
    case ActionTypes.SET_LOYALTY_RECOGNITION_NUMBER:
      return { ...state, loyaltyRecognitionNumber: action.value };
    case ActionTypes.SET_BOOKING_FEE_AND_TAX:
      return {
        ...state,
        bookingFee: action.fee,
        bookingFeeTax: action.tax,
      };
    case ActionTypes.SET_BOOKING_FEE:
      return {
        ...state,
        bookingFee: action.fee,
      };
    case ActionTypes.SET_BOOKING_FEE_STRATEGY:
      return {
        ...state,
        bookingFeeStrategy: action.bookingFeeStrategy,
      };
    case ActionTypes.SET_SEATS_MODEL:
      return { ...state, seatsModel: action.model };
    case ActionTypes.SET_EMBARGO_DATA:
      return { ...state, embargoData: action.value };
    case ActionTypes.SET_ERROR:
      return {
        ...state,
        error: { show: true, message: action.msg, peachCode: action.peachCode },
        loading: false,
      };
    case ActionTypes.CLEAR_ERROR:
      return { ...state, error: noError };
    case ActionTypes.ADD_SEAT:
      return { ...state, selectedSeats: [...state.selectedSeats, action.seat] };
    case ActionTypes.REMOVE_SEAT:
      return {
        ...state,
        selectedSeats: state.selectedSeats.filter(
          (x) => x.seatName !== action.seat.seatName
        ),
      };
    case ActionTypes.REMOVE_ALL_SEATS:
      return { ...state, selectedSeats: [] };
    case ActionTypes.SET_SELECTED_SEATS:
      return { ...state, selectedSeats: action.selectedSeats };
    case ActionTypes.SET_CUSTOMER_DETAILS:
      return { ...state, customer: action.customer };
    case ActionTypes.SET_PAYMENT_DETAILS:
      return { ...state, payment: action.payment };
    case ActionTypes.SET_HOSTED_PAYMENT:
      return {
        ...state,
        hostedPayments: {
          ...state.hostedPayments,
          [action.paymentProvider]: action.hostedPayment,
        },
        guestSessionToken: action.guestSessionToken,
      };
    case ActionTypes.REMOVE_HOSTED_PAYMENT:
      return {
        ...state,
        hostedPayments: {},
      };
    case ActionTypes.ADD_GIFTCARD:
      return {
        ...state,
        appliedGiftCards: [...state.appliedGiftCards, action.giftCard],
      };
    case ActionTypes.REMOVE_GIFTCARD:
      return {
        ...state,
        appliedGiftCards: state.appliedGiftCards.filter(
          (x) => x.giftCardNumber !== action.giftCard?.giftCardNumber
        ),
      };
    case ActionTypes.REMOVE_ALL_GIFTCARDS:
      return {
        ...state,
        appliedGiftCards: [],
      };
    case ActionTypes.SET_COUNTDOWN:
      return {
        ...state,
        countdownEndTime: action.secondsToExpiration
          ? moment().add(action.secondsToExpiration, 'seconds').format()
          : null,
        countdownExpired: false,
      };
    case ActionTypes.SET_COUNTDOWN_EXPIRED:
      return { ...state, countdownExpired: action.value };
    case ActionTypes.SET_CIRCUIT_CONFIG:
      return { ...state, config: action.config };
    case ActionTypes.SET_CIRCUIT_CONTENT:
      return { ...state, content: action.content };
    case ActionTypes.SET_SEND_ANALYTICS:
      return { ...state, sendAnalytics: action.value };
    case ActionTypes.SET_QUERY_STRING:
      return { ...state, queryString: action.value };
    case ActionTypes.SET_STEP:
      return { ...state, step: action.step };
    case ActionTypes.SET_KIOSK_SUBSTEP:
      return { ...state, kioskSubStep: action.kioskSubStep };
    case ActionTypes.SET_JOURNEY_TYPE:
      return { ...state, journeyType: action.journeyType };
    case ActionTypes.SET_REFUND:
      return { ...state, ...action.data };
    case ActionTypes.SET_CONCESSIONS: {
      return {
        ...state,
        concessions: action.concessionGroupings,
      };
    }
    case ActionTypes.ADD_CONCESSION: {
      const objectToUpdate = action.isFaB
        ? state.selectedConcessions
        : state.selectedGiftCards;
      let matchedConcession = findModifiedConcessionMatch(
        action.concession,
        objectToUpdate
      );
      if (matchedConcession) {
        if (action.isFaB) {
          matchedConcession.quantity =
            action.concession.quantity === 1
              ? matchedConcession.quantity + 1
              : matchedConcession.quantity + action.concession.quantity;

          return {
            ...state,
            selectedConcessions: {
              list: objectToUpdate.list,
            },
          };
        } else {
          matchedConcession.quantity += 1;

          return {
            ...state,
            selectedGiftCards: {
              list: objectToUpdate.list,
            },
          };
        }
      } else {
        const newConcession = action.concession;
        objectToUpdate.list.push(newConcession);
        if (action.isFaB) {
          return {
            ...state,
            selectedConcessions: {
              list: objectToUpdate.list,
            },
          };
        } else {
          return {
            ...state,
            selectedGiftCards: {
              list: objectToUpdate.list,
            },
          };
        }
      }
    }
    case ActionTypes.REMOVE_CONCESSION: {
      const objectToUpdate = action.isFaB
        ? state.selectedConcessions
        : state.selectedGiftCards;
      let matchedConcession = findModifiedConcessionMatch(
        action.concession,
        objectToUpdate
      );
      let countOfVisibleConcessions = objectToUpdate.list.reduce(
        (a, b) => a + (!b.hidden || 0),
        0
      );
      if (matchedConcession !== undefined) {
        if (action.isRemove) {
          matchedConcession.quantity = 0;
        } else {
          matchedConcession.quantity -= 1;
        }

        if (countOfVisibleConcessions >= 1 && matchedConcession.quantity <= 0) {
          matchedConcession.hidden = true;
          matchedConcession.id = undefined;
        }

        if (action.isFaB) {
          return {
            ...state,
            selectedConcessions: {
              list: objectToUpdate.list.filter((x) => !x.hidden),
            },
          };
        } else {
          return {
            ...state,
            selectedGiftCards: {
              list: objectToUpdate.list,
            },
          };
        }
      } else {
        return { ...state };
      }
    }
    case ActionTypes.CHANGE_CONCESSION_BY_INDEX: {
      const objectToUpdate = action.isFaB
        ? state.selectedConcessions
        : state.selectedGiftCards;
      let countOfVisibleConcessions = objectToUpdate.list.reduce(
        (a, b) => a + (!b.hidden || 0),
        0
      );
      const previousState = objectToUpdate.list[action.index];
      if (
        countOfVisibleConcessions >= 1 &&
        action.concession.quantity <= 0 &&
        previousState.quantity > 0 &&
        action.concession.variablePriceInCents ===
          previousState.variablePriceInCents
      ) {
        action.concession.hidden = true;
        action.concession.id = undefined;
      }
      objectToUpdate.list[action.index] = action.concession;
      if (action.isFaB) {
        return {
          ...state,
          selectedConcessions: {
            list: objectToUpdate.list.filter((x) => !x.hidden),
          },
        };
      } else {
        return {
          ...state,
          selectedGiftCards: {
            list: objectToUpdate.list,
          },
        };
      }
    }
    case ActionTypes.CHANGE_GIFTCARD_BY_ID: {
      let matchedConcession;
      const objectToUpdate = state.selectedGiftCards;
      if (action.context === 'swap') {
        matchedConcession = objectToUpdate.list.find(
          (x) => x.id === action.concession.id
        );
        if (!matchedConcession) {
          return state;
        }
        matchedConcession.cost = action.concession.cost;
        matchedConcession.variablePriceInCents =
          action.concession.variablePriceInCents;
        return {
          ...state,
          selectedGiftCards: {
            list: objectToUpdate.list,
          },
        };
      }
      matchedConcession = objectToUpdate.list.find(
        (x) =>
          x.id === action.concession.id && x.cost === action.concession.cost
      );
      if (action.context === 'addAndRemoveOthers') {
        const newList = [];
        const newConcession = action.concession;
        newConcession.quantity = 1;
        newList.push(newConcession);
        return {
          ...state,
          selectedGiftCards: {
            list: newList,
          },
        };
      }

      if (matchedConcession !== undefined) {
        switch (action.context) {
          case 'add':
            matchedConcession.quantity += 1;
            break;
          case 'remove':
            matchedConcession.quantity -= 1;
            break;
        }
        return {
          ...state,
          selectedGiftCards: {
            list: objectToUpdate.list,
          },
        };
      } else {
        const newConcession = action.concession;
        newConcession.quantity = 1;
        objectToUpdate.list.push(newConcession);
        return {
          ...state,
          selectedGiftCards: {
            list: objectToUpdate.list,
          },
        };
      }
    }
    case ActionTypes.CHANGE_DELIVERY_ITEM_ID: {
      return {
        ...state,
        deliveryItemId: action.deliveryItemId,
      };
    }
    case ActionTypes.CHANGE_DELIVERY_ITEM: {
      let matchedConcession;
      switch (action.context) {
        case 'insert':
          matchedConcession = action.concession;
          matchedConcession.isDeliveryItem = true;
          matchedConcession.quantity = action.concession.quantity;
          state.selectedGiftCards.list.push(matchedConcession);
          break;
        case 'update':
          matchedConcession = state.selectedGiftCards.list.find(
            (x) => x.id === action.concession.id
          );
          if (matchedConcession) {
            matchedConcession.quantity = action.concession.quantity;
          }
          break;
        case 'change':
          state.selectedGiftCards.list.forEach((c) => {
            if (c.isDeliveryItem && c.id !== action.concession.id) {
              c.quantity = 0;
            }
          });
          matchedConcession = state.selectedGiftCards.list.find(
            (x) => x.id === action.concession.id
          );
          if (matchedConcession) {
            matchedConcession.quantity = action.concession.quantity;
          } else {
            matchedConcession = action.concession;
            matchedConcession.isDeliveryItem = true;
            matchedConcession.quantity = action.concession.quantity;
            state.selectedGiftCards.list.push(matchedConcession);
          }
          break;
      }
      return {
        ...state,
        selectedGiftCards: {
          list: state.selectedGiftCards.list,
        },
        deliveryItemId: action.concession.id,
      };
    }
    case ActionTypes.SET_LOYALTY_TIER_SELECTION: {
      let otherConcessions, selectedConcessions;
      let matchedConcession = state.selectedConcessions.list.find(
        (x) => x.id === action.concession.id
      );
      if (matchedConcession !== undefined) {
        switch (action.context) {
          case 'add':
            matchedConcession.quantity += 1;
            otherConcessions = state.selectedConcessions.list.filter(
              (x) => x.id !== action.concession.id
            );
            otherConcessions?.forEach((c) => {
              c.quantity = 0;
            });
            break;
          case 'remove':
            matchedConcession.quantity -= 1;
            break;
        }
        selectedConcessions = {
          list: state.selectedConcessions.list,
        };
      } else {
        const newConcession = action.concession;
        newConcession.quantity = 1;

        state.selectedConcessions.list = [];
        state.selectedConcessions.list.push(newConcession);
        selectedConcessions = {
          list: state.selectedConcessions.list,
        };
      }
      return {
        ...state,
        selectedConcessions,
      };
    }
    case ActionTypes.CLEAR_CONCESSIONS: {
      if (action.isFaB) {
        return {
          ...state,
          selectedConcessions: {
            list: [],
          },
        };
      } else {
        return {
          ...state,
          selectedGiftCards: {
            list: [],
          },
        };
      }
    }
    case ActionTypes.CLEAR_AVAILABLE_CONCESSIONS: {
      return {
        ...state,
        concessions: null,
      };
    }
    case ActionTypes.SET_CONCESSIONS_ADDED:
      return { ...state, concessionsAdded: action.concessionsAdded };
    case ActionTypes.SET_GIFTCARDS_ADDED:
      return { ...state, giftCardsAdded: action.giftCardsAdded };
    case ActionTypes.SET_LANGUAGE:
      return {
        ...state,
        content: action.content,
        dazzlerContent: action.dazzlerContent,
        token: action.token,
        selectedLanguageCulture: action.culture,
      };
    case ActionTypes.SET_GIFTCARD_RECIPIENT:
      return { ...state, giftCardRecipient: action.giftCardRecipient };
    case ActionTypes.SET_VOUCHER_RECIPIENT:
      return { ...state, voucherRecipient: action.voucherRecipient };
    case ActionTypes.SET_PHYSICAL_GIFTCARD_RECIPIENT:
      return {
        ...state,
        physicalGiftCardRecipient: action.physicalGiftCardRecipient,
      };
    case ActionTypes.SET_SHOWTIMES:
      return {
        ...state,
        showtimes: action.showtimes,
      };
    case ActionTypes.SET_ALLOW_PAYMENT:
      return {
        ...state,
        maxRetriesExceeded: action.maxRetriesExceeded,
      };
    case ActionTypes.SET_CONCESSIONS_JOURNEY_PARENT_BOOKING_REF:
      return {
        ...state,
        concessionsJourneyParentBookingRef: action.bookingRef,
      };
    case ActionTypes.SET_DELIVERY_PREFERENCE:
      return {
        ...state,
        deliveryPreference: action.deliveryPreference,
      };
    case ActionTypes.SET_SELECTED_DELIVERY_WINDOW:
      return {
        ...state,
        selectedDeliveryWindow: action.selectedDeliveryWindow,
      };
    case ActionTypes.SET_DELIVERY_WINDOWS:
      return {
        ...state,
        deliveryWindows: action.deliveryWindows,
      };
    case ActionTypes.SET_GUEST_MARKETING:
      return {
        ...state,
        guestMarketing: action.guestMarketing,
      };
    case ActionTypes.SET_GUEST_NEWSLETTER_SIGN_UP:
      return {
        ...state,
        guestNewsletterSignUp: action.guestNewsletterSignUp,
      };
    case ActionTypes.SET_GUEST_SESSION_TOKEN:
      return {
        ...state,
        guestSessionToken: action.guestSessionToken,
      };
    case ActionTypes.SET_DATALAYER_POPULATED:
      return {
        ...state,
        dataLayerPopulated: action.dataLayerPopulated,
      };
    case ActionTypes.SET_HAS_VOUCHER_APPLIED:
      return {
        ...state,
        hasVoucherApplied: action.hasVoucherApplied,
      };
    case ActionTypes.SET_GRAND_TOTAL_WITH_DISCOUNT:
      return {
        ...state,
        grandTotalWithDiscount: action.grandTotalWithDiscount,
        totalDiscount: action.totalDiscount,
      };
    case ActionTypes.SET_DEALS_IN_TICKETS_STEP:
      return {
        ...state,
        dealsInTicketsStep: action.dealsInTicketsStep,
      };
    case ActionTypes.SET_APPLIED_DEALS_WITH_DISCOUNT:
      return {
        ...state,
        appliedDeals: action.appliedDeals,
        totalDiscount: action.totalDiscount,
      };
    case ActionTypes.SET_TOTAL_DISCOUNT:
      return {
        ...state,
        totalDiscount: action.totalDiscount,
      };
    case ActionTypes.SET_DEALS:
      return {
        ...state,
        deals: action.deals,
      };
    case ActionTypes.ADD_VOUCHER:
      return {
        ...state,
        appliedVouchers: [...state.appliedVouchers, action.voucher],
      };
    case ActionTypes.REMOVE_VOUCHER:
      return {
        ...state,
        appliedVouchers: state.appliedVouchers.filter(
          (x) => x.voucherCode !== action.voucher?.voucherCode
        ),
      };
    case ActionTypes.SET_HOSTED_PAYMENT_IN_PROGRESS:
      return {
        ...state,
        isHostedPaymentInProgress: action.isHostedPaymentInProgress,
      };
    case ActionTypes.SET_CONSENT:
      return {
        ...state,
        consent: action.consent,
      };
    case ActionTypes.INITIALIZE_AMAZONPAY_PAYMENT:
      return state;
    case ActionTypes.SET_SELECTED_PAYMENT_PROVIDER:
      return {
        ...state,
        selectedPaymentProvider: action.selectedPaymentProvider,
      };
    case ActionTypes.SET_TERMS_AND_CONDITIONS_CHECKED:
      return {
        ...state,
        termsAndConditionsChecked: action.checked,
      };
    case ActionTypes.SET_CART_SUMMARY_OPEN:
      return {
        ...state,
        isCartSummaryOpen: action.isCartSummaryOpen,
      };
    case ActionTypes.SUBMIT_MAKE_PAYMENT_REQUEST:
      return state;
    case ActionTypes.ADYEN_INITIATE_PAYMENT:
      return state;
    case ActionTypes.ADYEN_SUBMIT_ADDITIONAL_DETAILS:
      return state;
    case ActionTypes.ADD_CEA_CARD:
      return {
        ...state,
        appliedCeaCards: [...state.appliedCeaCards, action.ceaCard],
      };
    case ActionTypes.REMOVE_CEA_CARD:
      return {
        ...state,
        appliedCeaCards: state.appliedCeaCards.filter(
          (x) => x !== action.ceaCard
        ),
      };
    case ActionTypes.CLEAR_CEA_CARDS:
      return {
        ...state,
        appliedCeaCards: [],
      };
    case ActionTypes.SET_TICKET_CREDITS:
      return {
        ...state,
        ticketCredits: action.payload,
      };
    case ActionTypes.SET_TICKET_CREDITS_AVAILABLE:
      return {
        ...state,
        ticketCreditsAvailable: action.payload,
      };
    case ActionTypes.ADD_MEMBER_CARD:
      return {
        ...state,
        appliedMemberCards: [
          ...state.appliedMemberCards,
          action.memberCardAndTicket,
        ],
      };
    case ActionTypes.REMOVE_MEMBER_CARD:
      return {
        ...state,
        appliedMemberCards: state.appliedMemberCards.filter(
          (memberCard) =>
            memberCard?.memberCardNumber !== action.memberCardNumber
        ),
      };
    case ActionTypes.SET_DELIVERY_OPTION_BASED_ON_SEATING_AREA:
      return {
        ...state,
        deliveryOptionBasedOnSeatingArea: action.payload,
      };
    case ActionTypes.REMOVE_UNCOLLECTIBLE_CONCESSIONS:
      return {
        ...state,
        selectedConcessions: {
          list: state.selectedConcessions.list.filter(
            (c) => c?.isAvailableForPickupAtCounter
          ),
        },
      };
    case ActionTypes.CLEAR_MEMBER_CARDS:
      return {
        ...state,
        appliedMemberCards: [],
      };
    case ActionTypes.SET_SELECTED_SEASON_PASS:
      return {
        ...state,
        selectedSeasonPass: {
          ...state.selectedSeasonPass,
          ...action.payload,
        },
      };
    case ActionTypes.SET_SEASON_PASS_MOVIES:
      return {
        ...state,
        seasonPassMovies: action.payload,
      };
    case ActionTypes.SET_SELECTED_SEASON_PASS_SESSIONS:
      return {
        ...state,
        selectedSeasonPass: {
          ...state.selectedSeasonPass,
          sessions: [
            ...state.selectedSeasonPass.sessions,
            {
              movie: action.payload.movie,
              session: action.payload.session,
              seats: [],
            },
          ],
        },
      };
    case ActionTypes.REMOVE_SELECTED_SEASON_PASS_SESSION:
      return {
        ...state,
        selectedSeasonPass: {
          ...state.selectedSeasonPass,
          sessions: state.selectedSeasonPass.sessions.filter(
            (selectedSeasonPassSession) =>
              selectedSeasonPassSession.session.sessionId !==
              action.payload.sessionId
          ),
        },
      };
    default:
      return { ...state };
  }
};
