import React, { FC, memo } from 'react';

import { useSelector } from 'react-redux';

import { selectDazzlerLocale } from '../../../store/Selectors';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyFreeTextWidget'>;
};

export const FreeText: FC<Props> = ({ widget }) => {
  const selectedLocale = useSelector(selectDazzlerLocale);
  const localizedHtml =
    widget.shape?.html.find((html) => html.locale === selectedLocale)?.value ??
    '';

  return (
    <div className='row widget-row'>
      <div className='contained col-12'>
        <div dangerouslySetInnerHTML={{ __html: localizedHtml }} />
      </div>
    </div>
  );
};

export default memo(FreeText);
