/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  AttributeWarning,
  MovieRatingWarning,
  ScreenWarning,
} from '../../../@types/contentTypes';
import { JOURNEY_TYPES, SEASON_PASS_STEPS } from '../../../constants';
import * as StepPaths from '../../../constants/stepPaths';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import {
  getDisplayDateAndTime,
  renderShowtimeAttributeList,
  getScreenWarning,
  getAttributeWarnings,
  getMovieRatingWarning,
} from '../../../services/Helpers';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectCartSummary,
  selectConfig,
  selectContent,
  selectCustomer,
  selectJourneyTypeConfig,
  selectSelectedLanguageCulture,
  selectShowtimes,
  selectStep,
} from '../../../store/Selectors';
import { ReactComponent as SummaryCloseSvg } from '../../../svgs/summaryClose.svg';
import AddToCalendar from '../addtocalendar/AddToCalendar';
import CartSummaryDiscount from '../cartsummary/CartSummaryDiscount';
import CartSummaryItems from '../cartsummary/CartSummaryItems';
import CartSummaryTotal from '../cartsummary/CartSummaryTotal';
import Heading from '../heading/Heading';
import ContainedRow from '../layout/ContainedRow';
import MobileWallets from '../mobilewallets/MobileWallets';
import SelectedSeasonPassImage from '../seasonpass/SelectedSeasonPassImage';
import SelectedSeasonPassTitleAndLocation from '../seasonpass/SelectedSeasonPassTitleAndLocation';
import ShowtimePicker from '../showtimepicker/ShowtimePicker';
import ShowtimeWarning from '../showtimewarning/ShowtimeWarning';

interface Props {
  barcodeUrl?: string;
  displayAddToCalendar?: boolean;
  displayBarcode?: boolean;
  displayConfirmationNumber?: boolean;
  displayMobileWallets?: boolean;
  orderId?: string;
}

const ShowtimeHero: React.FC<Props> = ({
  barcodeUrl,
  displayAddToCalendar,
  displayBarcode,
  displayConfirmationNumber,
  displayMobileWallets,
  orderId,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isLargeScreenWidth } = useScreenWidth();

  const bookingData = useSelector(selectBookingData);
  const cartSummary = useSelector(selectCartSummary);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const showtimes = useSelector(selectShowtimes);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const step = useSelector(selectStep);

  const [showtimePickerOpen, setShowtimePickerOpen] = useState(false);

  const displaySummary =
    (pathname.startsWith('/payment') && !isLargeScreenWidth) ||
    (pathname.startsWith('/confirmation') && !isLargeScreenWidth);

  const allowShowtimeChange = config.allowShowtimeChange;

  const displayShowtimePicker =
    allowShowtimeChange &&
    (pathname.startsWith('/seats/') || pathname.startsWith('/tickets/'));

  const attributeWarnings: AttributeWarning[] = getAttributeWarnings(
    cartSummary.sessionAttributes,
    content.attributeWarnings
  );

  const screenWarning: ScreenWarning | undefined = getScreenWarning(
    cartSummary.screen,
    content.screenWarnings,
    config.currentCinema.cinemaId
  );

  const movieRatingWarnings: MovieRatingWarning[] = getMovieRatingWarning(
    cartSummary.rated,
    content.movieRatingWarnings
  );

  const displayWarningMessage =
    pathname.startsWith(`/${StepPaths.WARNING}`) &&
    (!!attributeWarnings.length ||
      screenWarning ||
      !!movieRatingWarnings.length);

  const showShowtimeHeroExtendedArea =
    displayShowtimePicker || displayWarningMessage;

  const dot = <span className='dot-separator'>·</span>;

  useEffect(() => {
    const fetchSessions = async () => {
      const url = `api/ShowtimePicker/Showtimes/
      ${bookingData.circuitId}/
      ${bookingData.cinemaId}/
      ${bookingData.filmId}`;

      const response = await backend.get(url);

      if (response.ok) {
        response.content &&
          dispatch(actionCreators.setShowtimes(response.content));
      }
    };
    if (
      showShowtimeHeroExtendedArea &&
      bookingData &&
      !showtimes &&
      !pathname.startsWith('/confirmation') &&
      !pathname.startsWith('/refund')
    ) {
      fetchSessions();
    }
  }, [
    bookingData,
    dispatch,
    showShowtimeHeroExtendedArea,
    pathname,
    showtimes,
  ]);

  const handleHideSessions = () => {
    document.documentElement.classList.remove('no-scroll');
    document.body.classList.remove('no-scroll');
    setShowtimePickerOpen(false);
  };

  const setShowShowtimes = (show: boolean) => {
    if (show) {
      document.documentElement.classList.add('no-scroll');
      document.body.classList.add('no-scroll');
      setShowtimePickerOpen(show);
    } else {
      handleHideSessions();
    }
  };

  const displaySeasonPassSummary =
    journeyTypeConfig.type === JOURNEY_TYPES.SEASON_PASS &&
    step === SEASON_PASS_STEPS.PAYMENT;

  if (displaySeasonPassSummary) {
    return (
      <div
        className='mt-2 text-center showtime-hero'
        data-testid='seasonpass-showtime-hero'
        sx={{
          boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
        }}
      >
        <ContainedRow>
          <SelectedSeasonPassImage />
          <SelectedSeasonPassTitleAndLocation />
          {!isLargeScreenWidth && (
            <div
              className='cart-summary mx-2 mt-1'
              sx={{
                backgroundColor: 'uiContainerBackground',
                borderTop: 'bodySeparator',
                '.cart-items': {
                  borderTop: 'none',
                },
                '.cart-summary-row': {
                  borderTop: 'bodySeparator',
                },
              }}
            >
              <CartSummaryItems />
              <CartSummaryDiscount />
              <CartSummaryTotal />
            </div>
          )}
        </ContainedRow>
      </div>
    );
  }

  if (
    !content ||
    !content.cartSummary ||
    !customer ||
    !cartSummary ||
    !cartSummary.sessionDate
  )
    return null;

  const backgroundImage = cartSummary.stillUrl
    ? cartSummary.stillUrl
    : cartSummary.posterUrl;

  const dateAndTime =
    cartSummary.sessionDate &&
    getDisplayDateAndTime(cartSummary.sessionDate, selectedLanguageCulture);

  return (
    <>
      {showShowtimeHeroExtendedArea && (
        <div
          className={classnames(showtimePickerOpen && 'showtime-picker-mask')}
          sx={{
            backgroundColor: 'bodyMask',
          }}
        />
      )}
      <div
        className={classnames(
          'mt-2 text-center showtime-hero',
          showShowtimeHeroExtendedArea &&
            showtimePickerOpen &&
            'hide-opacity showtime-picker-view mb-2'
        )}
        data-testid='showtime-hero'
        sx={{
          boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
        }}
      >
        <div className='showtime-hero-top'>
          <div
            className='backdrop blur'
            style={{ backgroundImage: `url(${backgroundImage}?width=770)` }}
          />
          <div className='caption'>
            {showShowtimeHeroExtendedArea && showtimePickerOpen && (
              <Button
                className='close-showtime-picker-button'
                onClick={() => handleHideSessions()}
                variant='link'
              >
                <SummaryCloseSvg className='icon' />
              </Button>
            )}

            <Heading size={2}>{cartSummary.title}</Heading>

            {!(showShowtimeHeroExtendedArea && showtimePickerOpen) && (
              <p className='tiny'>
                {cartSummary.rated && <span>{cartSummary.rated}</span>}
                {cartSummary.formattedDuration && cartSummary.rated && (
                  <span>{dot}</span>
                )}
                {cartSummary.formattedDuration && (
                  <span>{cartSummary.formattedDuration}</span>
                )}
              </p>
            )}

            {!(showShowtimeHeroExtendedArea && showtimePickerOpen) && (
              <p className='small extra-margin'>
                {renderShowtimeAttributeList(
                  cartSummary.sessionAttributes,
                  config.showtimeAttributeIconsInShowtimeHeroFormat,
                  true
                )}
              </p>
            )}

            <p className='extra-margin'>
              <b>{content.cartSummary.atLabel} </b>
              {cartSummary.theaterLocationName}
              {dot}
              {cartSummary.screen}
            </p>
            {(!displayShowtimePicker ||
              (displayShowtimePicker && showtimePickerOpen)) && (
              <p>
                <b>{content.cartSummary.onLabel} </b>
                {dateAndTime?.displayDate} - {dateAndTime?.displayTime}
              </p>
            )}
          </div>
        </div>
        {displayBarcode && (
          <div className='mt-3 px-2'>
            <img src={barcodeUrl} alt='ticket barcode' />
          </div>
        )}

        {displayMobileWallets && orderId && <MobileWallets orderId={orderId} />}

        {displayAddToCalendar && displayConfirmationNumber !== undefined && (
          <AddToCalendar
            displayConfirmationNumber={displayConfirmationNumber}
            locationCinemaName={config.currentCinema.title}
          />
        )}

        {displaySummary && (
          <div
            className='cart-summary px-2'
            sx={{
              backgroundColor: 'uiContainerBackground',
              '.cart-items:first-child': {
                borderTop: 'none',
              },
              '.cart-summary-row': {
                borderTop: 'bodySeparator',
              },
            }}
          >
            <CartSummaryItems />
            <CartSummaryDiscount />
            <CartSummaryTotal />
          </div>
        )}
        {(displayShowtimePicker || displayWarningMessage) && (
          <div
            className='showtime-hero-session-picker'
            sx={{
              backgroundColor: 'uiContainerBackground',
            }}
          >
            {showtimePickerOpen && (
              <h2 className='py-1'>{content.selectShowtimeText}</h2>
            )}

            {!showtimePickerOpen && displayShowtimePicker && (
              <div className='px-2'>
                <div
                  sx={{
                    borderBottom: 'bodySeparator',
                  }}
                >
                  <p className='py-1 m-0'>
                    <b>{content.cartSummary.onLabel} </b>
                    {dateAndTime?.displayDate} - {dateAndTime?.displayTime}
                  </p>
                </div>
              </div>
            )}

            <ShowtimePicker
              showShowtimes={showtimePickerOpen}
              setShowShowtimes={setShowShowtimes}
              displayShowtimePicker={displayShowtimePicker}
            />
          </div>
        )}
        {displayWarningMessage && (
          <ShowtimeWarning
            setShowShowtimes={setShowShowtimes}
            attributeWarnings={attributeWarnings}
            screenWarning={screenWarning}
            movieRatingWarnings={movieRatingWarnings}
          />
        )}
      </div>
    </>
  );
};

export default ShowtimeHero;
