import { mostReadable, TinyColor } from '@ctrl/tinycolor';
import type { Theme } from 'theme-ui';

import { Config } from '../@types/configTypes';
import {
  ButtonStyles,
  FormInputStyles,
  SidebarColors,
  TotalBarColors,
} from '../@types/enums';

// UI COLORS LIVE HERE AND NEVER CHANGE
const uiColors = {
  uiDark: '#212020',
  uiLight: '#F7F7F7',
  uiValid: '#23640b',
  uiError: '#A70A1D',
  uiInfo: '#0E3F87',
};
interface ThemeExtended extends Theme {
  boxes: object;
}
export const createTheme = (config: Config) => {
  const primary = new TinyColor(config.colors.primary);
  const accent = new TinyColor(config.colors.accent);
  const highlight = new TinyColor(config.colors.highlight);
  const muted = new TinyColor(config.colors.muted);
  const websiteBackground = new TinyColor(config.colors.websiteBackground);

  const seatAvailable = new TinyColor(config.colors.seatAvailable);
  const seatSelected = new TinyColor(config.colors.seatSelected);
  const seatUnvailable = new TinyColor(config.colors.seatUnavailable);

  const primaryButtonStyle = config.styles.primaryButtonStyle;
  const secondaryButtonStyle = config.styles.secondaryButtonStyle;
  const formInputStyle = config.styles.formInputStyle;
  const totalBarColor = config.styles.totalBarColor;
  const sidebarColor = config.styles.sidebarColor;

  const getTotalBarBackground = () => {
    switch (totalBarColor) {
      case TotalBarColors.PRIMARY:
        return primary;
      case TotalBarColors.ACCENT:
        return accent;
      case TotalBarColors.HIGHLIGHT:
        return highlight;
      case TotalBarColors.MUTED:
        return muted;
    }
  };

  const getSidebarBackground = () => {
    switch (sidebarColor) {
      case SidebarColors.WEBSITE_BACKGROUND:
        return darkenLightLightenDark(websiteBackground, 8);
      case SidebarColors.ACCENT:
        return accent;
      case SidebarColors.HIGHLIGHT:
        return highlight;
      case SidebarColors.MUTED:
        return muted;
    }
  };

  const darkenLightLightenDark = (tinyColor: TinyColor, percentage: number) => {
    return tinyColor.isLight()
      ? tinyColor.darken(percentage)
      : tinyColor.lighten(percentage);
  };

  const darkenDarkLightenLight = (tinyColor: TinyColor, percentage: number) => {
    return tinyColor.isLight()
      ? tinyColor.lighten(percentage)
      : tinyColor.darken(percentage);
  };

  const getRgba = (
    color: TinyColor | string | undefined,
    percentage: number
  ) => {
    return new TinyColor(color).setAlpha(percentage).toRgbString();
  };

  const mostReadableOnPrimaryBackground = mostReadable(config.colors.primary, [
    uiColors.uiLight,
    uiColors.uiDark,
  ])?.toHexString();

  const mostReadableOnAccentBackground = mostReadable(config.colors.accent, [
    uiColors.uiLight,
    uiColors.uiDark,
  ])?.toHexString();

  const mostReadableOnWebsiteBackground = mostReadable(
    config.colors.websiteBackground,
    [uiColors.uiLight, uiColors.uiDark]
  )?.toHexString();

  const leastReadableOnWebsiteBackground = new TinyColor(
    mostReadableOnWebsiteBackground
  ).isLight()
    ? uiColors.uiDark
    : uiColors.uiLight;

  const mostReadableOnFooterBackground = mostReadable(
    config.colors.footerBackground,
    [uiColors.uiLight, uiColors.uiDark]
  )?.toHexString();

  const mostReadableOnTotalBarBackground = mostReadable(
    getTotalBarBackground(),
    [uiColors.uiLight, uiColors.uiDark]
  )?.toHexString();

  const mostReadableOnSidebarBackground = mostReadable(getSidebarBackground(), [
    uiColors.uiLight,
    uiColors.uiDark,
  ])?.toHexString();

  const mostReadableOnSeatAvailable = mostReadable(seatAvailable, [
    uiColors.uiLight,
    uiColors.uiDark,
  ])?.toHexString();

  const mostReadableOnSeatSelected = mostReadable(seatSelected, [
    uiColors.uiLight,
    uiColors.uiDark,
  ])?.toHexString();

  const mostReadableOnSeatUnvailable = mostReadable(seatUnvailable, [
    uiColors.uiLight,
    uiColors.uiDark,
  ])?.toHexString();

  const getPrimaryButtonBackground = () => {
    switch (primaryButtonStyle) {
      case ButtonStyles.SOLID:
        return primary;
      case ButtonStyles.BORDER:
        return darkenDarkLightenLight(websiteBackground, 4);
    }
  };

  const getPrimaryButtonBackgroundHover = () => {
    switch (primaryButtonStyle) {
      case ButtonStyles.SOLID:
        return accent;
      case ButtonStyles.BORDER:
        return darkenDarkLightenLight(websiteBackground, 4);
    }
  };

  const getPrimaryButtonColor = () => {
    switch (primaryButtonStyle) {
      case ButtonStyles.SOLID:
        return primary.isDark() ? uiColors.uiLight : uiColors.uiDark;
      case ButtonStyles.BORDER:
        return darkenDarkLightenLight(websiteBackground, 4).isDark()
          ? uiColors.uiLight
          : uiColors.uiDark;
    }
  };

  const getPrimaryButtonColorHover = () => {
    switch (primaryButtonStyle) {
      case ButtonStyles.SOLID:
        return accent.isDark() ? uiColors.uiLight : uiColors.uiDark;
      case ButtonStyles.BORDER:
        return darkenDarkLightenLight(websiteBackground, 4).isDark()
          ? uiColors.uiLight
          : uiColors.uiDark;
    }
  };

  const getSecondaryButtonBackground = () => {
    switch (secondaryButtonStyle) {
      case ButtonStyles.SOLID:
        return accent;
      case ButtonStyles.BORDER:
        return darkenDarkLightenLight(websiteBackground, 8);
    }
  };

  const getSecondaryButtonBackgroundHover = () => {
    switch (secondaryButtonStyle) {
      case ButtonStyles.SOLID:
        return primary;
      case ButtonStyles.BORDER:
        return darkenDarkLightenLight(websiteBackground, 8);
    }
  };

  const getSecondaryButtonColor = () => {
    switch (secondaryButtonStyle) {
      case ButtonStyles.SOLID:
        return accent.isDark() ? uiColors.uiLight : uiColors.uiDark;
      case ButtonStyles.BORDER:
        return darkenDarkLightenLight(websiteBackground, 8).isDark()
          ? uiColors.uiLight
          : uiColors.uiDark;
    }
  };

  const getSecondaryButtonColorHover = () => {
    switch (secondaryButtonStyle) {
      case ButtonStyles.SOLID:
        return primary.isDark() ? uiColors.uiLight : uiColors.uiDark;
      case ButtonStyles.BORDER:
        return darkenDarkLightenLight(websiteBackground, 8).isDark()
          ? uiColors.uiLight
          : uiColors.uiDark;
    }
  };

  const getFormInputBackground = () => {
    switch (formInputStyle) {
      case FormInputStyles.WHITE_BACKGROUND:
        return '#ffffff';
      case FormInputStyles.THEME_BACKGROUND:
        return darkenDarkLightenLight(websiteBackground, 4).toHexString();
    }
  };

  const getFormInputColor = () => {
    switch (formInputStyle) {
      case FormInputStyles.WHITE_BACKGROUND:
        return uiColors.uiDark;
      case FormInputStyles.THEME_BACKGROUND:
        return websiteBackground.isLight() ? uiColors.uiDark : uiColors.uiLight;
    }
  };

  const getFormInputPlaceholder = () => {
    switch (formInputStyle) {
      case FormInputStyles.WHITE_BACKGROUND:
        return '#646464';
      case FormInputStyles.THEME_BACKGROUND:
        return websiteBackground.isLight() ? '#646464' : '#999999';
    }
  };

  const theme: ThemeExtended = {
    borders: {
      mostReadableOnWebsiteBackgroundBorder: `1px solid ${mostReadableOnWebsiteBackground}`,
      mostReadableOnWebsiteBackgroundBorderThick: `2px solid ${mostReadableOnWebsiteBackground}`,
      mostReadableOnWebsiteBackgroundRgbaBorder: `1px solid ${getRgba(
        mostReadableOnWebsiteBackground,
        0.4
      )}`,
      mostReadableOnTotalBarBackgroundBorder: `1px solid ${mostReadableOnTotalBarBackground}`,
      primaryBorder: `1px solid ${config.colors.primary}`,
      bodySeparator: `1px solid ${darkenLightLightenDark(
        websiteBackground,
        20
      ).toHexString()}`,
      bodySeparatorThick: `2px solid ${darkenLightLightenDark(
        websiteBackground,
        20
      ).toHexString()}`,
      boxSeparator: `1px solid ${darkenLightLightenDark(
        websiteBackground,
        25
      ).toHexString()}`,
      boxSeparatorDashed: `1px dashed ${darkenLightLightenDark(
        websiteBackground,
        25
      ).toHexString()}`,
      sidebarSeparator: `1px solid ${darkenLightLightenDark(
        getSidebarBackground(),
        20
      ).toHexString()}`,
      sidebarSeparatorDashed: `1px dashed ${darkenLightLightenDark(
        getSidebarBackground(),
        20
      ).toHexString()}`,
      footerSeparator: `1px solid ${getRgba(
        mostReadableOnFooterBackground,
        0.2
      )}`,
    },
    colors: {
      // COLOR PALETTE
      primary: config.colors.primary,
      accent: config.colors.accent,
      highlight: config.colors.highlight,
      muted: config.colors.muted,
      websiteBackground: config.colors.websiteBackground,
      headerBackground: config.colors.headerBackground,
      footerBackground: config.colors.footerBackground,
      // FONT COLORS
      mostReadableOnPrimaryBackground: mostReadableOnPrimaryBackground,
      mostReadableOnAccentBackground: mostReadableOnAccentBackground,
      mostReadableOnWebsiteBackground: mostReadableOnWebsiteBackground,
      leastReadableOnWebsiteBackground: leastReadableOnWebsiteBackground,
      mostReadableOnFooterBackground: mostReadableOnFooterBackground,
      mostReadableOnTotalBarBackground: mostReadableOnTotalBarBackground,
      mostReadableOnSidebarBackground: mostReadableOnSidebarBackground,
      mostReadableOnSeatAvailable: mostReadableOnSeatAvailable,
      mostReadableOnSeatSelected: mostReadableOnSeatSelected,
      mostReadableOnSeatUnvailable: mostReadableOnSeatUnvailable,
      // BACKGROUND COLORS
      boxBackground: darkenLightLightenDark(websiteBackground, 2).toHexString(),
      boxHeaderBackground: darkenLightLightenDark(
        websiteBackground,
        12
      ).toHexString(),
      boxHoverBackground: darkenLightLightenDark(
        websiteBackground,
        5
      ).toHexString(),
      boxBorderColor: mostReadableOnWebsiteBackground,
      totalBarBackground: getTotalBarBackground().toHexString(),
      totalBarBackgroundLighter: getTotalBarBackground()
        .lighten(4)
        .toHexString(),
      sidebarBackground: getSidebarBackground().toHexString(),
      bannerBackground: primary.lighten(8).toHexString(),
      primaryAlpha: getRgba(primary, 0.4),
      // BUTTONS
      primaryButtonBackground: getPrimaryButtonBackground().toHexString(),
      primaryButtonBackgroundHover:
        getPrimaryButtonBackgroundHover().toHexString(),
      primaryButtonColor: getPrimaryButtonColor(),
      primaryButtonColorHover: getPrimaryButtonColorHover(),
      secondaryButtonBackground: getSecondaryButtonBackground().toHexString(),
      secondaryButtonBackgroundHover:
        getSecondaryButtonBackgroundHover().toHexString(),
      secondaryButtonColor: getSecondaryButtonColor(),
      secondaryButtonColorHover: getSecondaryButtonColorHover(),
      // SEATS
      seatAvailable: config.colors.seatAvailable,
      seatSelected: config.colors.seatSelected,
      seatUnavailable: config.colors.seatUnavailable,
      seatSelectedBorder: new TinyColor(config.colors.seatSelected)
        .darken(4)
        .toHexString(),
      seatUnavailableBorder: new TinyColor(config.colors.seatUnavailable)
        .darken(4)
        .toHexString(),
      // FORMS
      formInputBackground: getFormInputBackground(),
      formInputColor: getFormInputColor(),
      formInputPlaceholder: getFormInputPlaceholder(),
      selectorButtonBackground: darkenDarkLightenLight(
        websiteBackground,
        8
      ).toHexString(),
      uiValid: uiColors.uiValid,
      uiError: uiColors.uiError,
      uiInfo: uiColors.uiInfo,
      uiContainerBackground: darkenDarkLightenLight(
        websiteBackground,
        4
      ).toHexString(),
      // OTHER
      bodyMask: getRgba(websiteBackground, 0.9),
      bodySeparatorColor: darkenLightLightenDark(
        websiteBackground,
        20
      ).toHexString(),
      accessibleOutlineColor: darkenLightLightenDark(primary, 5).toHexString(),
      categoryNavigationGradianLeftStart: `linear-gradient(90deg, ${getRgba(
        websiteBackground,
        1
      )} 0%, ${getRgba(websiteBackground, 0)} 100%)`,
      categoryNavigationGradianRightStart: `linear-gradient(90deg, ${getRgba(
        websiteBackground,
        0
      )} 0%, ${getRgba(websiteBackground, 1)} 100%)`,
    },
    fonts: {
      body: config.fonts.bodyFontFamily,
      heading: config.fonts.titleFontFamily,
      color: mostReadableOnWebsiteBackground,
    },
    shadows: {
      mostReadableOnWebsiteBackgroundShadow: `0 0 16px 0 ${getRgba(
        mostReadableOnWebsiteBackground,
        0.2
      )}`,
      errorShadow: `0 0 6px 0 ${getRgba(uiColors.uiError, 0.8)}`,
      formInputShadow: `0 0 6px 0 ${getRgba(
        mostReadableOnWebsiteBackground,
        0.4
      )}`,
      tooltipShadow: `0 4px 2px 0 ${getRgba(
        mostReadableOnWebsiteBackground,
        0.2
      )}`,
      primaryBlurryShadow: `0 0 16px 0 ${getRgba(config.colors.primary, 0.5)}`,
    },
    styles: {
      root: {
        backgroundColor: 'websiteBackground',
        color: 'mostReadableOnWebsiteBackground',
        fontFamily: 'body',

        'h1, h2, h3, .h1, .h2, .h3': {
          fontFamily: 'heading',
        },

        '.layout': {
          '.form-control': {
            backgroundColor: 'formInputBackground',
            borderColor: 'mostReadableOnWebsiteBackground',
            color: 'formInputColor',

            '&:focus': {
              boxShadow: 'formInputShadow',
            },

            '&.is-valid': {
              borderColor: 'uiValid',
            },

            '&.is-invalid': {
              borderColor: 'uiError',
              boxShadow: 'errorShadow',
            },
          },

          '.invalid-feedback': {
            color: 'mostReadableOnWebsiteBackground',
          },

          'input, textarea': {
            '&::-webkit-input-placeholder': {
              color: 'formInputPlaceholder',
            },

            '&::-moz-placeholder': {
              color: 'formInputPlaceholder',
            },

            '&:-ms-input-placeholder': {
              color: 'formInputPlaceholder',
            },

            '&::placeholder': {
              color: 'formInputPlaceholder',
            },
          },

          '.border-bottom-box-separator': {
            borderBottom: 'boxSeparator',
          },

          '.bordered': {
            backgroundColor: 'boxBackground',
            borderColor: 'mostReadableOnWebsiteBackground',
            color: 'mostReadableOnWebsiteBackground',

            '.checkbox-button': {
              '.selectable-button-svg': {
                '& .a': { fill: 'boxBackground' },
                '& .b': { fill: 'mostReadableOnWebsiteBackground' },
                '& .c': { fill: 'mostReadableOnWebsiteBackground' },
              },
            },
          },

          '.bordered-collapse, .stored-card-payment-item-row': {
            '&.glow': {
              boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
            },
          },

          '.banner': {
            backgroundColor: 'bannerBackground',
            color: 'mostReadableOnPrimaryBackground',

            svg: {
              '.a': {
                fill: 'primary',
              },
              '.b': {
                fill: 'mostReadableOnPrimaryBackground',
              },
              '.c': {
                fill: 'mostReadableOnPrimaryBackground',
              },
            },
          },

          '.checkbox-with-text': {
            button: {
              svg: {
                '.a': {
                  fill: 'websiteBackground',
                },
                '.b': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
                '.c': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
              },
            },
          },

          '.options-selector-container': {
            '.option-button': {
              backgroundColor: 'websiteBackground',
              border: 'mostReadableOnWebsiteBackgroundBorder',
              color: 'mostReadableOnWebsiteBackground',

              '&.selected, &:active': {
                borderWidth: '2px',
                boxShadow: 'formInputShadow',
              },

              '.check': {
                '.a': {
                  fill: 'websiteBackground',
                },
                '.b': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
                '.c': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
              },
            },
          },

          '.loyalty': {
            '.loyalty-rows-container': {
              '&:first-child': {
                borderTop: 'bodySeparator',
              },
            },
          },

          // CONCESSIONS
          '.concession': {
            borderBottom: 'bodySeparator',
          },

          // F & B
          '.accordion-icon': {
            '.icon': {
              fill: 'mostReadableOnWebsiteBackground',
            },
          },

          '.modifier-item': {
            borderTop: 'boxSeparator',

            button: {
              color: 'mostReadableOnWebsiteBackground',

              svg: {
                '.a': {
                  fill: 'boxBackground',
                },
                '.b': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
                '.c': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
              },

              '.unsel': {
                '.a': {
                  fill: 'boxBackground',
                },
                '.b': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
              },

              '.sel': {
                '.a': {
                  fill: 'boxBackground',
                },
                '.b': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
                '.c': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
              },
            },

            '&.selected': {
              button: {
                color: 'mostReadableOnWebsiteBackground',
              },
            },

            '&:first-child': {
              borderTop: 'boxSeparatorDashed',
            },

            '&-selected': {
              '& button svg': {
                '.a': {
                  fill: 'boxBackground',
                },
                '.b': {
                  fill: 'seatSelected',
                },
                '.c': {
                  fill: 'seatSelected',
                },
              },
            },
          },

          '.options-selector': {
            '&-bordered': {
              backgroundColor: 'boxBackground',
              borderColor: 'boxBorderColor',
            },
          },

          '.required-selection': {
            '&.valid': {
              color: 'seatSelected',
              '& svg': {
                '.a': {
                  fill: 'boxBackground',
                },
                '.b': {
                  fill: 'seatSelected',
                },
                '.c': {
                  fill: 'seatSelected',
                },
              },
            },
          },
        },

        // SEATS AND TICKETS
        '.all-selected': {
          color: 'seatSelected',

          '.a': {
            fill: 'websiteBackground',
          },
          '.b': {
            fill: 'seatSelected',
          },
          '.c': {
            fill: 'seatSelected',
          },
        },

        '.quantity-button-first': {
          color: 'mostReadableOnPrimaryBackground',
          background: 'primary',
          border: 'primaryBorder',

          '&:hover': {
            background: 'websiteBackground',
            border: 'primaryBorder',
            color: 'mostReadableOnWebsiteBackground',
          },
        },

        // PAYMENT
        '.giftcard-payment': {
          '.breakdown': {
            '.row': {
              borderBottom: 'boxSeparator',

              '&:first-child': {
                borderTop: 'boxSeparator',
              },
            },
          },
        },

        '.icon-info, .svg-info': {
          fill: 'mostReadableOnWebsiteBackground',
        },

        '.tooltip-inner': {
          backgroundColor: 'leastReadableOnWebsiteBackground',
          border: 'mostReadableOnWebsiteBackgroundBorder',
          boxShadow: 'tooltipShadow',
          color: 'mostReadableOnWebsiteBackground',
        },

        '.bs-tooltip-top .tooltip-arrow::before': {
          borderTopColor: 'mostReadableOnWebsiteBackground',
        },

        '.bs-tooltip-end .tooltip-arrow::before': {
          borderRightColor: 'mostReadableOnWebsiteBackground',
        },

        // GIFT CARDS
        '.giftcards': {
          '.giftcard-rows-container': {
            '.accordion-collapse': {
              borderBottom: 'bodySeparatorThick',
              '&:first-child': {
                borderTop: 'bodySeparatorThick',
              },
            },
            '&.summary-rows': {
              borderTop: 'bodySeparator',
            },
          },

          '.recipient-details': {
            '&.summary': {
              borderBottom: 'bodySeparator',
            },
          },
        },

        '.giftcards-row': {
          borderBottom: 'bodySeparator',
          '&:first-child': {
            borderTop: 'bodySeparator',
          },

          '.giftcard-button': {
            backgroundColor: 'selectorButtonBackground',
            border: 'mostReadableOnWebsiteBackgroundBorder',
            color: 'mostReadableOnWebsiteBackground',
            '.check': {
              '.a': {
                fill: 'selectorButtonBackground',
              },
              '.b': {
                fill: 'mostReadableOnWebsiteBackground',
              },
              '.c': {
                fill: 'mostReadableOnWebsiteBackground',
              },
            },

            '&:hover:not([disabled]), &.selected': {
              boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
              border: 'mostReadableOnWebsiteBackgroundBorderThick',
            },
          },
        },

        '.deal-text': {
          color: 'primary',
        },

        '.deal-icon': {
          '.a': {
            fill: 'primary',
          },
          '.b': {
            fill: 'websiteBackground',
          },
        },

        // MODAL
        '.layout-modal': {
          '.modal-content': {
            background: 'websiteBackground',
            borderColor: 'primary',
            color: 'mostReadableOnWebsiteBackground',
          },

          '.modal-title': {
            color: 'mostReadableOnWebsiteBackground',
            fontFamily: 'heading',
          },

          '.modal-body': {
            borderBottom: 'bodySeparator',
            borderTop: 'bodySeparator',
          },
        },

        // OTHER
        '.separator': {
          borderTop: 'bodySeparator',
        },

        '.box-separator': {
          borderTop: 'boxSeparator',
        },

        '.info-container': {
          backgroundColor: 'uiContainerBackground',
          borderColor: 'uiInfo',
        },

        '.warning-container': {
          backgroundColor: 'uiContainerBackground',
          borderColor: 'uiError',
        },

        '.border-y-solid': {
          borderTop: 'bodySeparator',
          borderBottom: 'bodySeparator',
        },

        '.btn-edit.btn-link': {
          border: 'mostReadableOnWebsiteBackgroundBorder',
          background: 'boxBackground',
          color: 'mostReadableOnWebsiteBackground',

          '&:hover': {
            background: 'mostReadableOnWebsiteBackground',
            color: 'boxBackground',
          },
        },

        // ACCESSIBILITY OUTLINE
        'a:focus, .btn-link:focus, .btn:focus, button:focus, button:focus-visible, input:focus, input.form-control:focus, .invalid-feedback:focus, label.form-label svg:focus, label.form-label:focus, label.form-label span:focus, .nav-link:focus, .quantity-button:focus, select:focus, select.form-control:focus':
          {
            outlineColor: 'accessibleOutlineColor',
            outlineWidth: '1px',
            outlineStyle: 'dashed',
            outlineOffset: '6px',
          },
      },
    },
    boxes: {
      containedLarge: {
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    layout: {
      container: { maxWidth: '100%' },
      fluid: {
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    },
    breakpoints: ['576px', '768px', '992px', '1200px', '1600px'],
    space: [0, 4, 5, 8, 12, 16, 20, 30, 40, 50],
  };

  return theme;
};
