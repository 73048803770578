import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  AddSeasonPassResponse,
  SeasonPassAddSessions,
  SeasonPassAddTicket,
} from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useGetSeasonPassShowtimes } from '../../../hooks/useGetSeasonPassShowtimes';
import useSetBookingFeeAndTaxForSeasonPass from '../../../hooks/useSetBookingFeeAndTaxForSeasonPass';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectSeasonPassMovies,
  selectSelectedSeasonPass,
  selectSource,
  selectToken,
} from '../../../store/Selectors';
import SeasonPassMovieItem from '../../common/seasonpass/SeasonPassMovieItem';
import SelectedSeasonPassStepContainer from '../../common/seasonpass/SelectedSeasonPassStepContainer';

const SeasonPassShowtimes: React.FC = () => {
  const dispatch = useDispatch();
  const boostNavigate = useBoostNavigate();

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const bookingData = useSelector(selectBookingData);
  const seasonPassMovies = useSelector(selectSeasonPassMovies);
  const token = useSelector(selectToken);
  const source = useSelector(selectSource);
  const selectedSeasonPass = useSelector(selectSelectedSeasonPass);

  useGetSeasonPassShowtimes();
  useSetBookingFeeAndTaxForSeasonPass();

  const stepRequiredDataAreValid =
    content?.seasonPass && selectedSeasonPass?.seasonPassItem;

  useEffect(() => {
    if (!stepRequiredDataAreValid) {
      boostNavigate.navigateToStartJourney();
    }
  }, [boostNavigate, stepRequiredDataAreValid]);

  if (!stepRequiredDataAreValid) {
    return null;
  }

  const handleContinueClick = async () => {
    dispatch(actionCreators.setLoading(true));

    const { cinemaId, circuitId } = bookingData;

    const seasonPassTickets: SeasonPassAddTicket[] =
      selectedSeasonPass.seasonPassItem.ticketTypes
        .filter((t) => t.quantity)
        .map((t) => ({
          quantity: t.quantity,
          ticketTypeId: t.ticketTypeId,
          externalSessionIds: selectedSeasonPass.sessions.map(
            (s) => s.session.externalSessionId
          ),
        }));

    const data: SeasonPassAddSessions = {
      dataToken: token,
      source,
      seasonPassTickets,
    };
    const response = await backend.post(
      `api/SeasonPass/AddSeasonPass?cinemaId=${cinemaId}&circuitId=${circuitId}`,
      data
    );
    if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
      const addSeasonPassResponse = response.content as AddSeasonPassResponse;
      dispatch(actionCreators.setToken(addSeasonPassResponse.dataToken));

      selectedSeasonPass.sessions = selectedSeasonPass.sessions.map((s) => ({
        ...s,
        seats: addSeasonPassResponse.orderDetails.selectedSeats
          .filter((ss) => ss.externalSessionId === s.session.externalSessionId)
          .map((ss) => ss.seatName),
      }));

      dispatch(actionCreators.setSelectedSeasonPass(selectedSeasonPass));
      dispatch(
        actionCreators.setBookingFee(
          addSeasonPassResponse.orderDetails.bookingFee
        )
      );
      if (config.enableCountDown) {
        dispatch(
          actionCreators.setCountDown(addSeasonPassResponse.secondsToExpiration)
        );
      }

      boostNavigate.navigateToNextStep();
    } else {
      dispatch(
        actionCreators.setError(
          content.error.seatMapErrorRichText,
          response.content.peachCode
        )
      );
    }
    dispatch(actionCreators.setLoading(false));
  };

  const canContinue =
    selectedSeasonPass.sessions.length -
      selectedSeasonPass.seasonPassItem.minRequiredMovies >=
    0;

  const continueButtonText = canContinue
    ? content.continueButtonText
    : content.seasonPass.selectSessionsButtonText;

  return (
    <SelectedSeasonPassStepContainer
      pageTitle={content.seasonPass.selectSessionsTitle}
      pageText={content.seasonPass.selectSessionsRichText}
      continueButtonText={continueButtonText}
      disableContinueButton={!canContinue}
      onContinueClicked={() => handleContinueClick()}
    >
      {seasonPassMovies?.map((movie) => (
        <SeasonPassMovieItem key={movie.filmId} movie={movie} />
      ))}
    </SelectedSeasonPassStepContainer>
  );
};

export default SeasonPassShowtimes;
