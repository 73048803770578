import React, { FC, memo } from 'react';

import classnames from 'classnames';

import { WidgetData } from '../types';
type Props = {
  widget: WidgetData<'TicketingCMSJourneySpacerWidget'>;
};

export const Spacer: FC<Props> = ({ widget }) => {
  const spacerSize = {
    HUGE: 5,
    LARGE: 4,
    MEDIUM: 3,
    SMALL: 2,
    TINY: 1,
    NONE: 0,
  };

  const selectedSize = widget.shape?.size ?? 'NONE';

  return <div className={classnames(`mt-${spacerSize[selectedSize]}`)}></div>;
};

export default memo(Spacer);
