import React, { useState } from 'react';

import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { FoodAndBeverageJourneyDeliveryType } from '../../../@types/enums';
import {
  JOURNEY_TYPES,
  ROUTE_PARAMS,
  SEASON_PASS_STEPS,
  STEP_PATHS,
} from '../../../constants';
import { getPreviousRoutePath } from '../../../services/JourneyService';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCurrentCinemaHomeUrl,
  selectJourneyTypeConfig,
  selectKioskSubStep,
  selectStep,
} from '../../../store/Selectors';
import { ReactComponent as ChevronLeftSvg } from '../../../svgs/chevronLeft.svg';
import SeasonPassBackToShowtimesWarningModal from '../seasonpass/SeasonPassBackToShowtimesWarningModal';

export const GoBackButton: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const bookingData = useSelector(selectBookingData);
  const step = useSelector(selectStep);
  const kioskSubStep = useSelector(selectKioskSubStep);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);

  const [showModal, setShowModal] = useState(false);

  const shouldHandleSubStep =
    (pathname.startsWith(`/${STEP_PATHS.KIOSK}`) ||
      pathname.startsWith(`/${STEP_PATHS.CONCESSIONS}/${STEP_PATHS.SELECT}`)) &&
    kioskSubStep === 'egc';

  const shouldShowSeasonPassGoBackToShowtimesWarning =
    journeyTypeConfig.type === JOURNEY_TYPES.SEASON_PASS &&
    step === SEASON_PASS_STEPS.SEATS;

  const navigateToPreviousRoutePath = () => {
    const routeParamIds = {
      [ROUTE_PARAMS.EXTERNAL_CINEMA_ID]: bookingData.externalCinemaId,
      [ROUTE_PARAMS.EXTERNAL_SESSION_ID]: bookingData.externalSessionId,
    };

    const previousRoutePath = getPreviousRoutePath(
      journeyTypeConfig,
      pathname,
      routeParamIds
    );

    navigate(previousRoutePath);
  };

  const handleBackClick = () => {
    if (
      pathname.includes('/confirmation/') ||
      pathname.startsWith('/startticketing/')
    ) {
      window.location.href = currentCinemaHomeUrl;
    } else {
      if (
        shouldHandleSubStep &&
        config.currentCinema.foodAndBeverageTicketingJourney !==
          FoodAndBeverageJourneyDeliveryType.Disabled
      ) {
        dispatch(actionCreators.setKioskSubStep('fab'));
        return;
      }

      if (shouldShowSeasonPassGoBackToShowtimesWarning) {
        setShowModal(true);
        return;
      }

      navigateToPreviousRoutePath();
    }
  };

  const shouldShowBackButton = () => {
    if (
      pathname.includes('/confirmation/') ||
      pathname.startsWith('/error') ||
      pathname.startsWith('/refund') ||
      !config ||
      !step ||
      !journeyTypeConfig
    ) {
      return false;
    }
    const allowSignIn =
      config.currentCinema?.allowSignIn || config.signIn.allowSignIn;

    if (allowSignIn && journeyTypeConfig.hasStepZeroLogin) {
      return step > 0;
    } else {
      return step > 1;
    }
  };

  return (
    <>
      {shouldShowBackButton() && (
        <Button
          onClick={handleBackClick}
          className='header-button go-back-button small d-flex align-items-center'
          data-testid='go-back'
          variant='link'
        >
          <div className='header-button-icon go-back-icon'>
            <ChevronLeftSvg />
          </div>
          <span className='d-none d-sm-inline-block'>{content.goBackText}</span>
        </Button>
      )}

      <SeasonPassBackToShowtimesWarningModal
        showModal={showModal}
        setShowModal={setShowModal}
        navigateToPreviousRoutePath={navigateToPreviousRoutePath}
      />
    </>
  );
};
