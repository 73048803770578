import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { forceNewJourneyKey, PEACH_CODES } from '../../../constants';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { getContentForError } from '../../../services/PeachErrorResolver';
import { actionCreators } from '../../../store/ActionCreators';
import { selectContent, selectStep } from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import Heading from '../../common/heading/Heading';
import RichText from '../../common/richtext/RichText';

const PaymentError: React.FC = () => {
  const boostNavigate = useBoostNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const content = useSelector(selectContent);
  const step = useSelector(selectStep);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const errorCode = parseInt(searchParams.get('code') ?? '', 10);
    const message: string = isNaN(errorCode)
      ? ''
      : getContentForError(errorCode, content);

    if (errorCode === PEACH_CODES.orderMaxRetriesReached) {
      dispatch(actionCreators.setMaxRetriesExceeded(true));
      setErrorMessage(content.error.paymentDeclinedRichText);
    } else {
      setErrorMessage(message);
    }
  }, [content, dispatch, searchParams]);

  return (
    <div className='error' data-testid='error'>
      <Row>
        <Col xs={12} className='contained'>
          <Heading size={2}>{content.error.title}</Heading>
          <RichText text={errorMessage} />
        </Col>
      </Row>
      <ActionButton
        onClick={() => {
          window.sessionStorage.setItem(forceNewJourneyKey, '1');
          boostNavigate.navigateToStep(step, {
            appendCinemaAndSessionIdsFromBookingData: true,
          });
        }}
        showIcon
        contained
        mx='mx-3'
        variant='primary'
      >
        {content.continueButtonText}
      </ActionButton>
    </div>
  );
};

export default PaymentError;
