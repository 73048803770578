import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { TrackingEvent } from '../../../@types/trackingTypes';
import { JOURNEY_TYPES } from '../../../constants';
import useAnalyticsTrackOnce from '../../../hooks/useAnalyticsTrackOnce';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useValidateJourneyShowtime } from '../../../hooks/useValidateJourneyShowtime';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectCartSummary,
  selectConfig,
  selectContent,
  selectJourneyType,
  selectJourneyTypeConfig,
} from '../../../store/Selectors';
import Heading from '../../common/heading/Heading';
import ContainedRow from '../../common/layout/ContainedRow';
import GuestLoginOptions from '../../common/login/loginoptions/GuestLoginOptions';
import LoggedInOptions from '../../common/login/loginoptions/LoggedInOptions';
import LoyaltyOnlyShowtimeOptions from '../../common/login/loginoptions/LoyaltyOnlyShowtimeOptions';
import LoyaltyOnlyUpgradeOptions from '../../common/login/loginoptions/LoyaltyOnlyUpgradeOptions';
import LoyaltyOnlyVouchersOptions from '../../common/login/loginoptions/LoyaltyOnlyVouchersOptions';
import ShowtimeHero from '../../common/showtimehero/ShowtimeHero';
import ShowtimeHeroConcessions from '../../common/showtimehero/ShowtimeHeroConcessions';
import DazzlerWrapper from '../../dazzlercommon/DazzlerWrapper';
import LegacyWrapper from '../../dazzlercommon/LegacyWrapper';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const boostNavigate = useBoostNavigate();
  const bookingData = useSelector(selectBookingData);
  const cartSummary = useSelector(selectCartSummary);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const journeyType = useSelector(selectJourneyType);

  useValidateJourneyShowtime();

  useAnalyticsTrackOnce(TrackingEvent.LOGIN_LANDING);

  const handleContinueToNextStepClick = () => {
    dispatch(actionCreators.setLoading(true));

    const appendCinemaAndSessionIds =
      !journeyTypeConfig.isConcessionsOnlyJourney &&
      journeyType !== JOURNEY_TYPES.SEASON_PASS;
    boostNavigate.navigateToStep(1, {
      appendCinemaAndSessionIdsFromBookingData: appendCinemaAndSessionIds,
      search: location.search,
    });

    dispatch(actionCreators.setLoading(false));
  };

  if (!cartSummary || !config || !content) return null;

  const isLoyaltyOnlyVouchers =
    journeyTypeConfig.type === JOURNEY_TYPES.VOUCHERS_ONLY &&
    config.signIn.isSignInRequiredForVouchersPurchase;
  const isLoyaltyUpgrade =
    journeyTypeConfig.type === JOURNEY_TYPES.LOYALTY_UPGRADE;

  const getHeadingText = () => {
    if (cartSummary.isLoyaltyOnlyShowtime) {
      return content.signIn.loyaltyOnlyShowtimeHeading;
    } else if (isLoyaltyOnlyVouchers) {
      return content.signIn.loyaltyOnlyVouchersHeading;
    } else if (isLoyaltyUpgrade) {
      return content.signIn.upgradeHeading;
    } else {
      return content.signIn.title;
    }
  };

  const renderOptions = () => {
    if (bookingData.isUserValidated) {
      return (
        <LoggedInOptions handleContinueClick={handleContinueToNextStepClick} />
      );
    } else if (cartSummary.isLoyaltyOnlyShowtime) {
      return <LoyaltyOnlyShowtimeOptions />;
    } else if (isLoyaltyOnlyVouchers) {
      return <LoyaltyOnlyVouchersOptions />;
    } else if (isLoyaltyUpgrade) {
      return <LoyaltyOnlyUpgradeOptions />;
    } else {
      return (
        <GuestLoginOptions
          handleContinueClick={handleContinueToNextStepClick}
        />
      );
    }
  };

  return (
    <div data-testid='login'>
      <LegacyWrapper step='login'>
        <ContainedRow classNameCol='text-center'>
          <Heading size={1}>{getHeadingText()}</Heading>
        </ContainedRow>

        <ContainedRow>
          {journeyTypeConfig.type === JOURNEY_TYPES.CONCESSIONS_ONLY ? (
            <ShowtimeHeroConcessions />
          ) : (
            <ShowtimeHero />
          )}
        </ContainedRow>

        {renderOptions()}
      </LegacyWrapper>

      <ContainedRow>
        <DazzlerWrapper step='login' />
      </ContainedRow>
    </div>
  );
};

export default Login;
