import React, { useCallback, useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  ConcessionGrouping,
  Concession,
  Deal,
  OrderDelivery,
} from '../../../@types/modelTypes';
import { TrackingEvent } from '../../../@types/trackingTypes';
import { useAnalytics } from '../../../analytics/analyticsContext';
import { PEACH_CODES } from '../../../constants';
import journeyTypes from '../../../constants/journeyTypes';
import useAnalyticsTrackOnce from '../../../hooks/useAnalyticsTrackOnce';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectGiftCardRecipient,
  selectLoading,
  selectQueryString,
  selectState,
  selectToken,
  selectTotalGiftCardsSelected,
  selectAllConcessions,
  selectSelectedGiftCards,
  selectTandCsAccepted,
  selectJourneyTypeConfig,
  selectEGiftCardGroup,
  selectGiftCardsAdded,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import DeliveryDetails from '../concessionsonlycommon/egiftcard/DeliveryDetailsEGiftCard';
import CollapsibleGiftCardsRow from '../concessionsonlycommon/egiftcard/multiItemLayout/CollapsibleGiftCardsRow';
import SingleOptionGiftCardsRow from '../concessionsonlycommon/egiftcard/singleItemLayout/SingleItemLayout';
import Heading from '../heading/Heading';
import ContainedRow from '../layout/ContainedRow';
import RichText from '../richtext/RichText';

const EGiftCardWrapper: React.FC = () => {
  const boostNavigate = useBoostNavigate();
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const globalState = useSelector(selectState);
  const bookingData = useSelector(selectBookingData);
  const allConcessions = useSelector(selectAllConcessions);
  const eGiftCardGroup = useSelector(selectEGiftCardGroup);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const giftCardRecipient = useSelector(selectGiftCardRecipient);
  const selectedGiftCardCount = useSelector(selectTotalGiftCardsSelected);
  const dataToken = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const loading = useSelector(selectLoading);
  const queryString = useSelector(selectQueryString);
  const selectedGiftCards = useSelector(selectSelectedGiftCards);
  const [validateForm, setValidateForm] = useState<boolean>(false);
  const [showRecipientForm, setShowRecipientForm] = useState<boolean>(false);
  const isTandCsAccepted = useSelector(selectTandCsAccepted);
  const giftCardsAddedToPos: boolean = useSelector(selectGiftCardsAdded);
  useAnalyticsTrackOnce(TrackingEvent.GIFTCARD_LANDING);

  const allRowsHaveValidQuantity = selectedGiftCards.list.every(
    (x: Concession) => x.quantity > 0 || x.hidden
  );

  const canAddAnotherRow =
    allRowsHaveValidQuantity &&
    selectedGiftCardCount < config.giftCardsPurchase.maxQuantityOfGiftCards;

  const totalRows = canAddAnotherRow
    ? selectedGiftCards.list.length + 1
    : selectedGiftCards.list.length;

  const navigateToNextPath = () => {
    const appendCinemaAndSessionIds =
      !journeyTypeConfig.isConcessionsOnlyJourney;

    boostNavigate.navigateToNextStep({
      appendCinemaAndSessionIdsFromUrl: appendCinemaAndSessionIds,
    });
  };

  const addGiftCardsSelectionToPos = async () => {
    dispatch(actionCreators.setLoading(true));

    if (hasGCConcessions || giftCardsAddedToPos) {
      const orderDelivery: OrderDelivery = {
        isGift: true,
        isGiftWrapped: false,
        giftMessage: giftCardRecipient.message,
        deliveryAddress: {
          name: giftCardRecipient.name,
          email: giftCardRecipient.email,
        },
        billingAddress: {
          email: customer.email,
          name: customer.name,
        },
      };

      selectedGiftCards?.list.forEach((c: Concession) => {
        c.orderDelivery = orderDelivery;
      });

      const data = {
        dataToken: dataToken,
        concessions: selectedGiftCards
          ? selectedGiftCards.list.filter((c: Concession) => !c.hidden)
          : [],
      };
      const path =
        journeyTypeConfig.type === journeyTypes.GIFTCARDS_ONLY
          ? 'AddEGiftCards'
          : 'AddEGiftCardsToTicketing';
      const response = await backend.post(`api/GiftCard/${path}`, data);
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setToken(response.content.dataToken));
        dispatch(actionCreators.setGiftCardsAddedToPos(hasGCConcessions));
        dispatch(actionCreators.setBookingFee(response.content.bookingFee));
        dispatch(
          actionCreators.setGrandTotalWithDiscount(
            response.content.grandTotalWithDiscount,
            response.content.totalDiscount
          )
        );
        analytics?.track(TrackingEvent.PICK_GIFTCARD, { globalState });
        navigateToNextPath();
      } else {
        dispatch(
          actionCreators.setError(
            content.error.concessionsCouldNotBeAddedRichText,
            PEACH_CODES.concessionsCouldNotBeAdded
          )
        );
      }
    }
    dispatch(actionCreators.setLoading(false));
  };

  const handleContinueToPaymentWithNoGiftCardsSelected = () => {
    if (giftCardsAddedToPos) {
      addGiftCardsSelectionToPos();
    } else {
      navigateToNextPath();
    }
  };

  const handleContinueToPaymentClick = () => {
    if (!validateForm) {
      setValidateForm(true);
    }
    addGiftCardsSelectionToPos();
  };

  const getConcessionData = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));
    const path = `api/GiftCard/GetEGiftCards/${bookingData.circuitId}/${bookingData.cinemaId}`;
    const response = await backend.get(path);
    const shouldProcess =
      response.ok && response.content.peachCode === PEACH_CODES.noError;
    if (!shouldProcess) {
      dispatch(actionCreators.setLoading(false));
      return;
    }
    const deals = response.content.deals?.filter(
      (x: Deal) => x.isConcessionRelated && !x.isTicketRelated
    );
    if (deals) {
      dispatch(actionCreators.setDeals(deals));
    }
    const groupList: ConcessionGrouping[] =
      response.content.listConcessionGrouping;
    const concessionGrouping: ConcessionGrouping = groupList[0];
    const items: Concession[] = concessionGrouping.items;
    const searchParams = new URLSearchParams(queryString);
    const queryStringId = searchParams.get('id')?.toLowerCase();
    const getIdToCompare = (id: string) => id.substring(id.indexOf('_') + 1);
    const processQueryString = () => {
      const selectedConcession =
        items.find(
          (concession) => getIdToCompare(concession.id) === queryStringId
        ) ?? null;
      if (selectedConcession) {
        if (!selectedConcession.isVariablePriceItem) {
          dispatch(
            actionCreators.changeGiftCardById(selectedConcession, 'add')
          );
        }
      }
    };
    if (queryStringId) {
      processQueryString();
    }
    const concessionGroups = allConcessions ?? [];
    concessionGroups.push(concessionGrouping);
    dispatch(actionCreators.setConcessions(concessionGroups));
    dispatch(actionCreators.setLoading(false));
  }, [allConcessions, bookingData, dispatch, queryString]);

  useEffect(() => {
    if (!eGiftCardGroup && bookingData) {
      getConcessionData();
    }
  }, [bookingData, eGiftCardGroup, getConcessionData]);

  const hasGCConcessions = selectedGiftCards
    ? selectedGiftCards.list.some((x: Concession) => x.quantity > 0)
    : false;

  const getGiftCardRows = () => {
    if (eGiftCardGroup === undefined) return null;

    const useMultiOptionRows =
      journeyTypeConfig.isConcessionsOnlyJourney &&
      eGiftCardGroup.items.length > 1 &&
      eGiftCardGroup.items.every((x) => !x.isVariablePriceItem);
    const giftCardRows: JSX.Element[] = [];

    if (useMultiOptionRows) {
      for (let index = 0; index < totalRows; index++) {
        giftCardRows.push(
          <CollapsibleGiftCardsRow
            selectedConcessionIndex={index}
            key={`giftcardrow_${index}`}
          />
        );
      }
    } else {
      giftCardRows.push(<SingleOptionGiftCardsRow key={0} />);
    }

    return giftCardRows;
  };

  useEffect(() => {
    !hasGCConcessions && setShowRecipientForm(false);
  }, [hasGCConcessions]);

  if (!content || !config) return null;

  return (
    <>
      <ContainedRow>
        <Heading className='mt-3 text-center' size={2}>
          1. {content.giftCards.selectionHeading}
        </Heading>
        <RichText
          text={
            journeyTypeConfig.isConcessionsOnlyJourney
              ? content.giftCards.instructionsRichText
              : content.kiosk.giftCardUpsellRichText
          }
        />
      </ContainedRow>

      {eGiftCardGroup && eGiftCardGroup.items.length > 0 ? (
        <Row>
          <Col>
            <div className='giftcard-rows-container my-4 px-2'>
              {getGiftCardRows()}
            </div>
            {!showRecipientForm ? (
              <ActionButton
                disabled={
                  journeyTypeConfig.type === journeyTypes.GIFTCARDS_ONLY &&
                  !hasGCConcessions
                }
                mx='mx-3'
                onClick={() => {
                  if (hasGCConcessions) {
                    setShowRecipientForm(true);
                  } else {
                    handleContinueToPaymentWithNoGiftCardsSelected();
                  }
                }}
                sticky={true}
                showIcon={true}
                contained
                variant='primary'
                showCartSummaryButtonOnMobile
              >
                {hasGCConcessions
                  ? content.giftCards.giftCardSelectionContinueButtonText
                  : content.continueToPaymentText}
              </ActionButton>
            ) : (
              <>
                <DeliveryDetails isPageValidated={validateForm} />
                <ActionButton
                  mx='mx-3'
                  sticky={false}
                  showIcon={true}
                  contained
                  onClick={handleContinueToPaymentClick}
                  disabled={
                    !hasGCConcessions ||
                    !giftCardRecipient.isValid ||
                    !customer.isValid ||
                    !isTandCsAccepted
                  }
                  stickyMobileDesktop={true}
                  variant='primary'
                  showCartSummaryButtonOnMobile
                >
                  {content.giftCards.recipientDetailsContinueButtonText}
                </ActionButton>
              </>
            )}
          </Col>
        </Row>
      ) : (
        !loading && (
          <>
            <ContainedRow classNameCol='mt-1'>
              <div className='warning-container mt-3 p-3'>
                <p>{content.giftCards.noGiftCardsMessage}</p>
              </div>
            </ContainedRow>
            <ContainedRow classNameCol='mt-1'>
              <ActionButton
                onClick={() => {
                  handleContinueToPaymentWithNoGiftCardsSelected();
                }}
                disabled={false}
                stickyMobileDesktop={true}
                sticky={true}
                showIcon
                contained
                mx='mx-3'
                variant='primary'
                showCartSummaryButtonOnMobile
              >
                {content.continueToPaymentText}
              </ActionButton>
            </ContainedRow>
          </>
        )
      )}
    </>
  );
};

export default EGiftCardWrapper;
