import React, { useEffect, useCallback } from 'react';

import { createBrowserHistory } from 'history';
import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { ConfirmationRequestModel } from '../../../@types/modelTypes';
import { TrackingEvent } from '../../../@types/trackingTypes';
import { BARCODE_FORMATS, forceNewJourneyKey } from '../../../constants';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import useAnalyticsTrackOnce from '../../../hooks/useAnalyticsTrackOnce';
import { useCinemaHomeUrlUpdater } from '../../../hooks/useCinemaHomeUrlUpdater';
import { useConfirmationTracking } from '../../../hooks/useConfirmationTracking';
import { createConfirmation } from '../../../services/Helpers';
import { getStepNumberForDisplay } from '../../../services/JourneyService';
import { buildQueryString } from '../../../services/QueryStringHelper';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectAppliedGiftCards,
  selectBookingData,
  selectConfig,
  selectConfirmationData,
  selectContent,
  selectCurrentCinemaHomeUrl,
  selectCustomer,
  selectGuestSessionToken,
  selectJourneyType,
  selectSource,
  selectStep,
  selectToken,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import Address from '../../common/address/Address';
import AddToCalendar from '../../common/addtocalendar/AddToCalendar';
import RegisterForCarParkingSection from '../../common/confirmation/RegisterForCarParkingSection';
import GoogleMap from '../../common/googlemap/GoogleMap';
import Heading from '../../common/heading/Heading';
import ContainedRow from '../../common/layout/ContainedRow';
import MobileWallets from '../../common/mobilewallets/MobileWallets';
import GiftCardBreakDown from '../../common/payment/giftcardcomponents/GiftCardBreakDown';
import RichText from '../../common/richtext/RichText';
import ShowtimeHero from '../../common/showtimehero/ShowtimeHero';
import SignUpSection from '../../common/signup/SignUpSection';

const Confirmation: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { orderId } = useParams();
  const [cookies] = useCookies();
  const { isLargeScreenWidth } = useScreenWidth();

  const appliedGiftCards = useSelector(selectAppliedGiftCards);
  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const confirmationData = useSelector(selectConfirmationData);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const source = useSelector(selectSource);
  const step = useSelector(selectStep);
  const token = useSelector(selectToken);
  const guestSessionToken = useSelector(selectGuestSessionToken);
  const journeyType = useSelector(selectJourneyType);
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);

  useConfirmationTracking('Ticket');

  useAnalyticsTrackOnce(TrackingEvent.TICKET_CHECKOUT);

  useCinemaHomeUrlUpdater();

  const fetchData = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));
    const qs = buildQueryString({
      circuitId: searchParams.get('circuitId') ?? undefined,
      languageCulture: cookies.preferredLanguageCulture,
    });
    const url = `api/Confirmation/${orderId}${qs}`;

    let body: ConfirmationRequestModel | undefined;

    if (guestSessionToken) {
      body = {
        DataToken: token,
        GuestSessionToken: guestSessionToken,
      };
    }

    const response = await backend.post(url, body);
    if (response.ok) {
      const data = response.content;
      const confirmation = createConfirmation(data, customer, journeyType);
      dispatch(actionCreators.setConfirmation(confirmation));
    }

    dispatch(actionCreators.setGuestSessionToken(undefined));

    dispatch(actionCreators.setLoading(false));
  }, [
    dispatch,
    searchParams,
    cookies.preferredLanguageCulture,
    orderId,
    guestSessionToken,
    token,
    customer,
    journeyType,
  ]);

  // fetch data
  useEffect(() => {
    if (!confirmationData || confirmationData.externalOrderId !== orderId) {
      fetchData();
    }

    window.sessionStorage.setItem(forceNewJourneyKey, '1');
  }, [confirmationData, orderId, fetchData]);

  const history = createBrowserHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  history.listen(({ location, action }) => {
    if (action === 'POP') {
      window.location.replace(currentCinemaHomeUrl);
    }
  });

  if (!config || !content || !confirmationData) return null;

  const knownBarcodeFormat =
    BARCODE_FORMATS[config.currentCinema.barcodeFormat];
  const barcodeHeight = knownBarcodeFormat === 'QR' ? 150 : 60;
  const barcodeWidth = knownBarcodeFormat === 'QR' ? 150 : 264;
  const barcodeUrl = `/Image/GetBarcode/${confirmationData.externalOrderId}/${barcodeHeight}/${barcodeWidth}?circuitId=${config.circuitId}`;
  const shouldShowBarcode =
    knownBarcodeFormat !== 'None' && knownBarcodeFormat !== undefined;
  const showSummaryInShowtimeHero = !isLargeScreenWidth;

  const shouldShowConfirmationNumber =
    config.currentCinema.showConfirmationId &&
    confirmationData.bookingConfirmationId !==
      confirmationData.codeUsedInBarcode &&
    !!confirmationData.bookingConfirmationId;

  const shouldShowSignUpSection =
    config.signIn.isSignUpWithinBoostEnabled &&
    !bookingData?.isUserValidated &&
    token;

  return (
    <div className='confirmation text-center' data-testid='confirmation'>
      <ContainedRow>
        <Heading size={1}>
          {getStepNumberForDisplay(step)}
          {content.confirmation.title}
        </Heading>
      </ContainedRow>

      <ContainedRow>
        <ShowtimeHero
          barcodeUrl={barcodeUrl}
          displayAddToCalendar={showSummaryInShowtimeHero}
          displayConfirmationNumber={shouldShowConfirmationNumber}
          displayBarcode={shouldShowBarcode && showSummaryInShowtimeHero}
          displayMobileWallets={showSummaryInShowtimeHero}
          orderId={orderId}
        />
      </ContainedRow>

      {shouldShowBarcode && !showSummaryInShowtimeHero && (
        <ContainedRow classNameCol='mt-4'>
          <img src={barcodeUrl} alt='ticket barcode' />
        </ContainedRow>
      )}

      {!showSummaryInShowtimeHero && (
        <>
          <MobileWallets orderId={orderId} />
          <AddToCalendar
            displayConfirmationNumber={shouldShowConfirmationNumber}
            locationCinemaName={config.currentCinema.title}
          />
        </>
      )}

      {shouldShowSignUpSection && (
        <ContainedRow>
          <SignUpSection />
        </ContainedRow>
      )}

      <ContainedRow>
        <Heading className='mt-5' size={2}>
          {content.confirmation.bookingReferenceSubTitle}
        </Heading>
        {confirmationData.codeUsedInBarcode && (
          <p>{confirmationData.codeUsedInBarcode}</p>
        )}
      </ContainedRow>

      {config.currentCinema.showConfirmationId &&
        confirmationData.bookingConfirmationId !==
          confirmationData.codeUsedInBarcode && (
          <ContainedRow>
            <Heading className='mt-5' size={2}>
              {content.confirmation.confirmationIdSubTitle}
            </Heading>
            {shouldShowConfirmationNumber && (
              <p>{confirmationData.bookingConfirmationId}</p>
            )}
          </ContainedRow>
        )}

      <ContainedRow>
        <Heading className='mt-5' size={2}>
          {content.confirmation.yourTicketsSubTitle}
        </Heading>
        {customer.email && <p>{customer.email}</p>}
      </ContainedRow>

      <ContainedRow>
        <Heading className='mt-5' size={2}>
          {content.confirmation.collectTicketsSubTitle}
        </Heading>
        <RichText
          className='text-start'
          text={content.confirmation.collectTicketsRichText}
        />
      </ContainedRow>

      <Address />

      <GoogleMap title={content.confirmation.googleMapSubTitle} />

      <RegisterForCarParkingSection />

      {appliedGiftCards && appliedGiftCards.length > 0 && (
        <ContainedRow>
          <Heading className='mt-5' size={2}>
            {content.confirmation.giftCardsSubTitle}
          </Heading>
          <RichText
            className='text-start'
            text={content.confirmation.giftCardsRichText}
          />
          <div className='giftcard-payment px-3'>
            <GiftCardBreakDown
              pricingStrategy='confirmation'
              variant='confirmation'
            />
          </div>
        </ContainedRow>
      )}

      {(content.confirmation.aboutYourBookingTitle ||
        content.confirmation.aboutYourBookingRichText) && (
        <ContainedRow>
          <Heading className='mt-5' size={2}>
            {content.confirmation.aboutYourBookingTitle}
          </Heading>
          <RichText
            className='text-start'
            text={content.confirmation.aboutYourBookingRichText}
          />
        </ContainedRow>
      )}

      {content.confirmation.promoSpaceRichText && (
        <ContainedRow>
          <RichText
            className='text-start mt-5'
            text={content.confirmation.promoSpaceRichText}
          />
        </ContainedRow>
      )}

      {source !== 'app' && (
        <ActionButton
          onClick={() => (window.location.href = currentCinemaHomeUrl)}
          sticky={
            config.seats.useStickyButton && config.tickets.useStickyButton
          }
          showIcon
          contained
          mx='mx-3'
          variant='primary'
        >
          {content.continueButtonText}
        </ActionButton>
      )}

      {config.confirmation.trackingCodes && (
        <RichText text={config.confirmation.trackingCodes} className='d-none' />
      )}
    </div>
  );
};

export default Confirmation;
