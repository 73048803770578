import React from 'react';

import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import {
  selectContent,
  selectCurrentCinemaHomeUrl,
} from '../../../../store/Selectors';
import { ReactComponent as IconArrowSvg } from '../../../../svgs/iconArrow.svg';
import Heading from '../../heading/Heading';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';
import LoginSection from '../LoginSection';
import Register from '../Register';

const LoyaltyOnlyShowtimeOptions: React.FC = () => {
  const content = useSelector(selectContent);
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);

  return (
    <>
      <ContainedRow classNameCol='text-center'>
        <Heading className='mt-4' size={2}>
          {content.signIn.loyaltyOnlyShowtimeMemberHeading}
        </Heading>
        <RichText text={content.signIn.loyaltyOnlyShowtimeMemberRichText} />
        <LoginSection />
      </ContainedRow>

      <Register showDivider />

      <ContainedRow classNameCol='text-center'>
        <Button
          onClick={() => (window.location.href = currentCinemaHomeUrl)}
          variant='link'
          size='sm'
          className='align-items-center go-back-to-website-button justify-content-center text-uppercase'
        >
          <IconArrowSvg className='arrow' />
          {content.goBackText}
        </Button>
      </ContainedRow>
    </>
  );
};

export default LoyaltyOnlyShowtimeOptions;
